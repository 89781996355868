import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../store/Store";

import { SettingButton } from "../../styled/SettingButton";

import { UpdatePendingSpinner } from "../UpdatePendingSpinner";
import { HomePhoneDisplay } from "./HomePhoneDisplay";
import { EditableHomePhone } from "./EditableHomePhone";
import {
  cancelHomePhoneUpdate,
  updateHomePhone,
} from "../../../store/customer-profile/CustomerProfileSlice";
import {
  isPhoneNumberEmpty,
  isValidHomePhone,
  removeAllSpaces,
} from "../Utils/PhoneUtils";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { SpinnerContainer } from "../../styled/SpinnerContainer";
import ProfileSettingRocket, {
  SettingClickEvent,
  SettingFieldStatus,
} from "../ProfileSettingRocket";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import { ThemedMessageRocket } from "ccp-common-ui-components";

type FormData = {
  homePhone: string;
};

const getValidationFailedMessage = (error: string) => () => {
  return (
    <ThemedMessageRocket
      data-testid="homePhone-validation-error"
      type="error"
      text={`${error} Please try again.`}
    />
  );
};
const UpdateSuccessfulMessage = () => {
  return (
    <ThemedMessageRocket
      type="success"
      data-testid="homePhone-success"
      text="Your home number has been updated."
    />
  );
};
const UpdateFailedMessage = () => {
  return (
    <ThemedMessageRocket
      type="error"
      data-testid="homePhone-update-error"
      text="We weren’t able to update your home number. Please try again later."
    />
  );
};

export function HomePhoneSetting() {
  const homePhoneInputName = "homePhone";

  const [isEditing, setIsEditing] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const isRocketEnabled = useRocketEnabled();
  const [Message, setMessage] = useState<React.FunctionComponent>();

  const { homePhone: originalHomePhone } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const { isUpdatingHomePhone } = useSelector(
    (state: RootState) => state.customerProfile
  );

  const homePhoneRef = useRef(originalHomePhone);

  const hasPreExistingHomePhone = !!originalHomePhone;

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    getValues,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm<FormData>({
    defaultValues: {
      [homePhoneInputName]: originalHomePhone,
    },
  });

  const dispatch = useDispatch();

  const onEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    clearErrors();
    setIsEditing(true);
    setIsEditClicked(true);
  };

  const onSubmit = (data: FormData) => {
    const isNullOrUndefined =
      data.homePhone === null || data.homePhone === undefined;
    const newHomePhone = isNullOrUndefined
      ? ""
      : removeAllSpaces(data.homePhone);
    const homePhoneNotUpdated = newHomePhone === originalHomePhone;
    const isEmpty = isPhoneNumberEmpty(newHomePhone);

    if (homePhoneNotUpdated || (!hasPreExistingHomePhone && isEmpty)) {
      dispatch(cancelHomePhoneUpdate());
      if (!homePhoneNotUpdated) {
        setMessage(() => UpdateFailedMessage);
      }
      reset({
        [homePhoneInputName]: originalHomePhone,
      });
    } else {
      dispatch(updateHomePhone(newHomePhone));
      setMessage(() => UpdateSuccessfulMessage);
      reset({
        [homePhoneInputName]: newHomePhone,
      });
    }
    setIsEditing(false);
  };

  const setHomePhone = (value: string) => {
    const isNotEmpty = !isPhoneNumberEmpty(value);
    const shouldValidate = isSubmitted && isNotEmpty;

    setValue(homePhoneInputName, value, {
      shouldDirty: true,
      shouldValidate,
    });

    homePhoneRef.current = value;

    if (!shouldValidate) {
      clearErrors();
    }
  };

  // Focus edit button after going back to read mode
  useEffect(() => {
    if (
      isEditClicked &&
      !isEditing &&
      editButtonRef.current &&
      !isUpdatingHomePhone
    ) {
      editButtonRef.current.focus();
    }
  }, [isEditClicked, isEditing, isUpdatingHomePhone]);

  useEffect(() => {
    homePhoneRef.current = originalHomePhone;
    setValue(homePhoneInputName, originalHomePhone);
  }, [originalHomePhone, setValue, isSubmitSuccessful]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const buttonText = () => {
    if (isEditing) {
      return "Save home phone";
    } else if (originalHomePhone) {
      return "Edit home phone";
    } else {
      return "Add home phone";
    }
  };

  const editMode = (
    <>
      <EditableHomePhone
        autoFocus
        id="home-number"
        errorMessage={errors.homePhone?.message}
        defaultValue={homePhoneRef.current}
        {...register(homePhoneInputName, {
          validate: {
            validHomePhone: (value) => isValidHomePhone(value),
          },
          onChange: (e) => {
            setHomePhone(e.target.value);
          },
        })}
      />
      <SpinnerContainer>
        <SettingButton type="submit" data-testid="save-button">
          {buttonText()}
        </SettingButton>
      </SpinnerContainer>
    </>
  );

  const displayMode = (
    <>
      <HomePhoneDisplay />
      <SpinnerContainer>
        {isUpdatingHomePhone ? (
          <UpdatePendingSpinner data-testid="update-pending-spinner" />
        ) : (
          <SettingButton
            data-testid="edit-button"
            ref={editButtonRef}
            type="button"
            withIcon={hasPreExistingHomePhone}
            onClick={(e) => onEditButtonClick(e)}
          >
            {buttonText()}
          </SettingButton>
        )}
      </SpinnerContainer>
    </>
  );

  const enterEditState = () => {
    setMessage(undefined);
    setIsEditing(true);
    setIsEditClicked(true);
  };

  const exitEditState = () => {
    setIsEditing(false);
    setIsEditClicked(false);
    reset();
    setMessage(undefined);
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHomePhone(event.target.value);
  };

  const handleSave = (_event: SettingClickEvent) => {
    const values = getValues();
    const validOrError = isValidHomePhone(values.homePhone);
    if (validOrError === true) {
      onSubmit(values);
    } else {
      setMessage(() =>
        getValidationFailedMessage(validOrError || "This number is invalid.")
      );
      document.getElementById(homePhoneInputName)?.focus();
    }
  };

  return isRocketEnabled ? (
    <ProfileSettingRocket
      name="Home number"
      value={getValues().homePhone}
      handleEdit={enterEditState}
      handleCancel={exitEditState}
      handleChange={updateValue}
      handleSave={handleSave}
      id={homePhoneInputName}
      inputType="tel"
      status={
        isEditing
          ? SettingFieldStatus.Editable
          : isUpdatingHomePhone
          ? SettingFieldStatus.PendingUpdate
          : SettingFieldStatus.Readable
      }
      MessageComponent={Message}
      linkEnabled
      link={originalHomePhone ? "Edit" : "Add"}
    />
  ) : (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="home-phone-form"
      noValidate
    >
      <SettingLine>
        <SettingName htmlFor="home-number">Home number:</SettingName>
        {isEditing ? editMode : displayMode}
      </SettingLine>
    </form>
  );
}
