import { forwardRef } from "react";
import styled from "styled-components";
import { headers } from "../../../common/styles/RocketColours";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import { rdsPadding } from "@coles/rocket";

interface LabelHeadingProps {
  hasError: boolean;
}

interface RadioButtonProps {
  id: string;
  label: string;
  description: string;
  hasError: boolean;
  name: string;
  value: any;
  defaultChecked: boolean;
}

const StyledLabel = styled.label`
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-left: 26px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    box-shadow: 0 0 0 1px #898d8e;
    display: inline-block;
    border-radius: 100%;
    top: 2px;
    left: 0;
    background-color: white;
    transition: box-shadow 0.2s;
  }

  &:hover:before,
  .radio-input:focus + &:before {
    box-shadow: 0 0 0 3px ${headers.black900};
  }

  &:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #6e6e6e;
    border-radius: 100%;
    display: none;
    top: 5px;
    left: 3px;
  }

  .radio-input:checked + &:after {
    display: inline-block;
  }

  .radio-input:active + &:after {
    background-color: ${headers.black900};
  }
`;

const StyledLabelHeading = styled.span<LabelHeadingProps>`
  color: ${(props) => (props.hasError ? headers.error : "inherit")};
  font-weight: ${(props) => (props.hasError ? 600 : "inherit")};
`;

const StyledInput = styled.input`
  position: absolute;
  width: 0;
  opacity: 0;
`;

const RadioButtonDescription = styled.p`
  padding-left: 3px;
  font-size: 14px;
  color: black;
`;

const RadioButtonDescriptionRocket = styled.p`
  font-size: 14px;
  color: black;
  ${rdsPadding.s3.vertical}
`;

export const RadioButton = forwardRef<any, RadioButtonProps>(
  (inputProps, ref) => {
    const isRocketEnabled = useRocketEnabled();

    const { id, label, description, hasError } = inputProps;

    const radioButtonDescription = isRocketEnabled ? (
      <RadioButtonDescriptionRocket>{description}</RadioButtonDescriptionRocket>
    ) : (
      <RadioButtonDescription>{description}</RadioButtonDescription>
    );
    return (
      <>
        <StyledInput
          {...inputProps}
          id={id}
          ref={ref}
          className="radio-input"
          type="radio"
        />
        <StyledLabel htmlFor={id}>
          <StyledLabelHeading hasError={hasError}>{label}</StyledLabelHeading>
          {radioButtonDescription}
        </StyledLabel>
      </>
    );
  }
);
