import styled from "styled-components";
import emailIcon from "../../assets/icons/email.svg";
import phoneIcon from "../../assets/icons/phone.svg";
import { SettingLine } from "../styled/SettingLine";
import { CustomerProfileAddressContact } from "../../store/customer-profile-address/CustomerProfileAddressSlice";

const AddressSettingLine = styled(SettingLine)`
  flex-direction: column;
`;

const StyledIcon = styled.img`
  width: 16px;
  height: 16px;
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
`;

const ContactSetting = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const ContactValue = styled.div`
  margin: -4px 10px;
  word-break: break-word;
`;

interface CustomerProfileAddressContacts {
  contacts: Array<CustomerProfileAddressContact>;
}

const format = (contact: string, length: number) => {
  return contact ? contact.replace(/ /g, "").substring(0, length) : "";
};

const Contact = (props: { value: string; icon: string; alt: string }) => (
  <ContactSetting data-testid={props.alt}>
    <StyledIcon src={props.icon} alt={props.alt} />
    <ContactValue>{props.value}</ContactValue>
  </ContactSetting>
);

const AddressContacts = ({ contacts }: CustomerProfileAddressContacts) => {
  const hasContacts = contacts && contacts.length > 0;

  const email =
    contacts.find((contact) => contact.key === "email")?.value ?? "";
  const phone =
    contacts.find((contact) => contact.key === "phone")?.value ?? "";

  return hasContacts ? (
    <AddressSettingLine>
      {email && (
        <Contact value={format(email, 50)} icon={emailIcon} alt="email" />
      )}
      {phone && (
        <Contact value={format(phone, 10)} icon={phoneIcon} alt="phone" />
      )}
    </AddressSettingLine>
  ) : (
    <></>
  );
};

export default AddressContacts;
