import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";

import styled from "styled-components";
import { SettingValue } from "../../styled/SettingValue";
import { MessageContainer } from "../../styled/MessageContainer";
import { UpdateFailureMessage } from "../UpdateFailureMessage";
import { mfaPreferences } from "./mfaPreferences";
import useRocketEnabled from "../../../hooks/useRocketEnabled";

const DisplayValue = styled.span`
  flex-basis: 100%;
`;

const MfaDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export function MfaDisplay() {
  const isRocketEnabled = useRocketEnabled();
  const { mfaPreference } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const selectedMfaPreference = mfaPreferences.find(
    (p) => p.value === mfaPreference
  );

  const errorMessage = useSelector(
    (state: RootState) => state.customerProfile.mfaPreferenceErrorMessage
  );

  return isRocketEnabled ? (
    <MfaDisplayContainer>
      <span>{selectedMfaPreference?.label}</span>
      <span>{selectedMfaPreference?.description}</span>
    </MfaDisplayContainer>
  ) : (
    <SettingValue data-testid="mfa-preference-display">
      <MessageContainer>
        <DisplayValue>
          <strong>{selectedMfaPreference?.label}</strong>
          <br />
          {selectedMfaPreference?.description}
        </DisplayValue>

        {errorMessage && (
          <UpdateFailureMessage data-testid="update-failure-message">
            {errorMessage}
          </UpdateFailureMessage>
        )}
      </MessageContainer>
    </SettingValue>
  );
}
