import { useEffect, useRef } from "react";
import { IconCircle } from "../common/IconCircle";
import { clearPasswordDetails } from "../../store/update-password/UpdatePasswordSlice";
import { useDispatch } from "react-redux";
import { ThemedButton } from "ccp-common-ui-components";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoContainer,
} from "../styled/InfoPage";
import { Layout } from "../styled/Layout";
import { BackButtonProps } from "./ChangePasswordPage";
import { ButtonContainer } from "./ChangePasswordSuccessPageStyles";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";

export function ChangePasswordSuccessPage({ backButtonUrl }: BackButtonProps) {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();

  const { handleNavigation } = useReturnUrl(backButtonUrl);

  useEffect(() => {
    dispatch(clearPasswordDetails());
  }, [dispatch]);

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  return (
    <Layout>
      <InfoContainer>
        <IconCircle decorative={true} />
        <CenteredHeader
          tabIndex={-1}
          ref={headingRef}
          className="sentry-unmask"
        >
          Password changed
        </CenteredHeader>
        <CenteredParagraph className="sentry-unmask">
          Your password has been changed successfully.
        </CenteredParagraph>
        <ButtonContainer>
          <ThemedButton
            onClick={() => handleNavigation()}
            className="sentry-unmask"
          >
            Continue
          </ThemedButton>
        </ButtonContainer>
      </InfoContainer>
    </Layout>
  );
}
