import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { RootState } from "../../../store/Store";

import {
  cancelPostcodeUpdate,
  PostcodeFields,
  updatePostcode,
} from "../../../store/customer-profile/CustomerProfileSlice";
import { SettingLink } from "../../styled/SettingLink";
import editIcon from "../../../assets/icons/edit.svg";
import styled from "styled-components";
import { PostcodeDisplay } from "./PostcodeDisplay";
import { ContainerRow } from "../../../common/styles/Containers";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import ProfileSettingRocket, {
  CustomInputComponentProps,
  SettingClickEvent,
  SettingFieldStatus,
} from "../ProfileSettingRocket";
import { AddEditPostcodeInput } from "../../addEditPostcode/AddEditPostcodeInput";
import { useForm } from "react-hook-form";
import {
  AutoSuggestOption,
  ThemedMessageRocket,
} from "ccp-common-ui-components";

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

const UppercaseSpan = styled.span`
  text-transform: uppercase;
`;

const PostcodeValueDisplay = ({ value }: { value?: PostcodeFields }) => (
  <UppercaseSpan>
    {value ? `${value.suburb || ""}, ${value.state}, ${value.postcode}` : "-"}
  </UppercaseSpan>
);

type FormData = {
  postcode: string;
  suburb: string;
  state: string;
};

const generateInitialAutocompleteValue = (
  originalPostcode?: PostcodeFields
) => {
  if (
    !originalPostcode ||
    (originalPostcode?.suburb === "" &&
      originalPostcode.state === "" &&
      originalPostcode.postcode === "")
  ) {
    return null;
  } else {
    return {
      label: `${originalPostcode.suburb}, ${originalPostcode.state}, ${originalPostcode.postcode}`,
      value: originalPostcode,
    };
  }
};

const PostcodeErrorMessage = () => {
  const { postcodeErrorMessage } = useSelector(
    (state: RootState) => state.customerProfile
  );
  return <ThemedMessageRocket type="error" text={postcodeErrorMessage} />;
};

const PostcodeSuccessMessage = () => {
  return (
    <ThemedMessageRocket type="success" text="Your postcode has been updated" />
  );
};

export function PostcodeSetting() {
  const isRocketEnabled = useRocketEnabled();
  const { locality: originalPostcode } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const { postcodeErrorMessage } = useSelector(
    (state: RootState) => state.customerProfile
  );
  const [Message, setMessage] = useState<React.FunctionComponent>();
  const [isEditing, setIsEditing] = useState(false);
  const [userChangedValue, setUserChangedValue] = useState<boolean>(false);

  const [autocompleteValue, setAutocompleteValue] =
    useState<AutoSuggestOption | null>(
      generateInitialAutocompleteValue(originalPostcode)
    );

  useEffect(() => {
    if (postcodeErrorMessage) {
      setMessage(() => PostcodeErrorMessage);
    }
  }, [postcodeErrorMessage]);
  useEffect(() => {
    setAutocompleteValue(generateInitialAutocompleteValue(originalPostcode));
  }, [originalPostcode]);

  const { register, setValue, setError, clearErrors, reset, getValues } =
    useForm<FormData>();

  const buttonText = originalPostcode ? "Edit postcode" : "Add postcode";

  const enterEditState = () => {
    setMessage(undefined);
    setIsEditing(true);
  };

  const exitEditState = (success?: boolean) => {
    setIsEditing(false);
    reset();
    if (success) {
      setMessage(() => PostcodeSuccessMessage);
    } else {
      setMessage(undefined);
    }
    setAutocompleteValue(generateInitialAutocompleteValue(originalPostcode));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    register("postcode", { required: true });
    register("suburb", { required: true });
    register("state", { required: true });
  }, [register]);

  const mapAutocompleteValueToLocalityPayload = (
    option: AutoSuggestOption
  ): {
    postcode: string;
    state: string;
    suburb: string;
  } => {
    return {
      postcode:
        option && option.value && option.value.postcode
          ? option.value.postcode
          : "",
      state:
        option && option.value && option.value.state ? option.value.state : "",
      suburb:
        option && option.value && option.value.suburb
          ? option.value.suburb
          : "",
    };
  };

  useEffect(() => {
    if (autocompleteValue) {
      const localityPayload =
        mapAutocompleteValueToLocalityPayload(autocompleteValue);

      setValue("postcode", localityPayload.postcode);
      setValue("suburb", localityPayload.suburb);
      setValue("state", localityPayload.state);
      clearErrors("postcode");
    } else {
      setValue("postcode", "");
      setValue("suburb", "");
      setValue("state", "");
    }
  }, [autocompleteValue, setValue, setError, clearErrors]);

  const onSubmit = (data: FormData) => {
    if (!userChangedValue) {
      dispatch(cancelPostcodeUpdate());
      return;
    }
    dispatch(
      updatePostcode({
        suburb: data.suburb,
        state: data.state,
        postcode: data.postcode,
      })
    );

    reset();
    exitEditState(true);
  };

  const updateValue = useMemo(
    () => (value: AutoSuggestOption) => {
      setAutocompleteValue(value);
      setUserChangedValue(true);
    },
    [setAutocompleteValue, setUserChangedValue]
  );

  const handleSave = (_event: SettingClickEvent) => {
    const values = getValues();
    onSubmit(values);
  };

  const MemoPostcodeInput = useMemo(
    () => (props: CustomInputComponentProps<AutoSuggestOption>) =>
      (
        <AddEditPostcodeInput
          {...props}
          value={props.value}
          description="To locate your nearest store"
          onChange={updateValue}
        />
      ),
    [updateValue]
  );

  return isRocketEnabled ? (
    <ProfileSettingRocket<AutoSuggestOption, PostcodeFields>
      name="Postcode/suburb"
      handleEdit={enterEditState}
      handleCancel={exitEditState}
      handleSave={handleSave}
      status={
        isEditing
          ? SettingFieldStatus.Editable
          : false
          ? SettingFieldStatus.PendingUpdate
          : SettingFieldStatus.Readable
      }
      id="postcode"
      linkEnabled
      link={originalPostcode ? "Edit" : "Add"}
      InputComponent={MemoPostcodeInput}
      MessageComponent={Message}
      DisplayComponent={PostcodeValueDisplay}
      value={isEditing ? autocompleteValue || undefined : originalPostcode}
    />
  ) : (
    <ContainerRow>
      <SettingLine>
        <SettingName>Postcode/suburb:</SettingName>
        <PostcodeDisplay />
        <SettingLink>
          {originalPostcode && (
            <EditIcon
              data-testid="edit-icon"
              src={editIcon}
              alt=""
              aria-hidden
            />
          )}
          <Link data-testid="add-edit-postcode-link" to="/add-edit-postcode">
            {buttonText}
          </Link>
        </SettingLink>
      </SettingLine>
    </ContainerRow>
  );
}
