import { useEffect, useRef } from "react";
import { IconCircle } from "./IconCircle";
import { StyledButtonWrapper } from "../styled/StyledButtonWrapper";
import {
  CenteredHeader,
  CenteredHeaderRocket,
  CenteredParagraph,
  CenteredParagraphRocket,
  InfoContainer,
  InfoContainerRocket,
  InfoPageThemedButton,
} from "../styled/InfoPage";
import { Layout } from "../styled/Layout";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { ThemedButtonRocket } from "ccp-common-ui-components";

const VerificationErrorPageLayout = styled(Layout)`
  width: 514px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

const VerificationErrorPageRocketLayout = styled(Layout)`
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export interface ResultPageProps {
  header: string;
  description: string;
  buttonText: string;
  action: Function;
  hasError: boolean;
}

export function ResultPage(props: ResultPageProps) {
  const isRocketEnabled = useRocketEnabled();

  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  function buttonClick() {
    props.action();
  }

  const verificationErrorPageLayout = (
    <VerificationErrorPageLayout>
      <InfoContainer>
        <IconCircle hasError={props.hasError} decorative={true} />
        <CenteredHeader
          tabIndex={-1}
          ref={headingRef}
          hasError={props.hasError}
          className="sentry-unmask"
        >
          {props.header}
        </CenteredHeader>
        <CenteredParagraph className="sentry-unmask">
          {props.description}
        </CenteredParagraph>
        <StyledButtonWrapper>
          <InfoPageThemedButton onClick={buttonClick} className="sentry-unmask">
            {props.buttonText}
          </InfoPageThemedButton>
        </StyledButtonWrapper>
      </InfoContainer>
    </VerificationErrorPageLayout>
  );

  const verificationErrorPageRocketLayout = (
    <VerificationErrorPageRocketLayout>
      <InfoContainerRocket>
        <IconCircle hasError={props.hasError} decorative={true} />
        <CenteredHeaderRocket
          tabIndex={-1}
          autoFocus
          hasError={props.hasError}
          className="sentry-unmask"
          data-testid="centered-header-rocket"
        >
          {props.header}
        </CenteredHeaderRocket>
        <CenteredParagraphRocket className="sentry-unmask">
          {props.description}
        </CenteredParagraphRocket>
        <ThemedButtonRocket
          isFullWidth
          label={props.buttonText}
          onClick={buttonClick}
          className="sentry-unmask"
        />
      </InfoContainerRocket>
    </VerificationErrorPageRocketLayout>
  );

  const layout = isRocketEnabled
    ? verificationErrorPageRocketLayout
    : verificationErrorPageLayout;

  return layout;
}
