import styled from "styled-components";
import { CustomerProfileAddressFields } from "../../store/customer-profile-address/CustomerProfileAddressSlice";
import AddAddressFormRocket from "./add-address-form/AddAddressFormRocket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setAddressToBeEdited } from "../../store/add-address/AddAddressSlice";
import { AddressCardRocket } from "./view-addresses/AddressCardRocket";
import { PaginationRocket } from "ccp-common-ui-components";
import { useEffect, useState } from "react";
import { rdsMargin } from "@coles/rocket";

interface AddressListProps {
  addresses: Array<CustomerProfileAddressFields>;
}

const AddressListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const PaginationRocketContainer = styled.div`
  ${rdsMargin.s4.vertical};
`;

const AddressListRocket = ({ addresses }: AddressListProps) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState<
    CustomerProfileAddressFields[]
  >([]);

  const itemsPerPage = 4;
  const hasAddresses = addresses && addresses.length > 0;

  const editAddress = useSelector(
    (state: RootState) => state.addAddress.addressToBeEdited
  );

  const setEditAddress = (editAddress: CustomerProfileAddressFields | null) => {
    dispatch(setAddressToBeEdited(editAddress));
  };

  useEffect(() => {
    const newTotalPages = Math.ceil(addresses.length / itemsPerPage);
    setTotalPages(newTotalPages);

    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages || 1);
    } else {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentItems(addresses.slice(startIndex, endIndex));
    }
  }, [addresses, currentPage, itemsPerPage]);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const pagedAddresses = currentItems.map((address, index) => (
    <AddressCardRocket
      address={address}
      key={`${address.id}-${index}`}
      editAddressId={editAddress === null ? null : editAddress.id}
      setEditAddress={setEditAddress}
    />
  ));

  return (
    <>
      <AddressListContainer>
        <AddAddressFormRocket
          address={editAddress}
          setEditAddress={setEditAddress}
        />
        {hasAddresses && (
          <>
            {pagedAddresses}
            <PaginationRocketContainer>
              <PaginationRocket
                defaultPage={1}
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={handlePageChange}
              />
            </PaginationRocketContainer>
          </>
        )}
      </AddressListContainer>
    </>
  );
};

export default AddressListRocket;
