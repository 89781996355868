import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";

import { AutocompleteInputFieldRocket } from "ccp-common-ui-components";

import { getCustomerProfileAction } from "../../store/customer-profile/CustomerProfileActions";
import useDebounce from "../../common/hooks/useDebounce";

import { AutoSuggestOption } from "../../store/add-edit-postcode/AddEditPostcodeSlice";
import { getMatchingPostcodeSuggestions } from "../../store/add-edit-postcode/AddEditPostcodeSlice";

import { WarningIcon } from "../common/WarningIcon";

import { AutocompleteDropDownMessage } from "../../common/styles/Autocomplete";

const AUTOCOMPLETE_INPUT_LIMIT = 3;

interface AddEditPostcodeInputProps {
  autoFocus: boolean;
  id: string;
  onChange: (value: AutoSuggestOption) => void;
  value: any;
  "aria-invalid": boolean;
  "aria-describedby": string;
  hasError: boolean;
  description?: string;
}

export function AddEditPostcodeInput({
  autoFocus,
  id,
  onChange,
  value,
  "aria-invalid": ariaInvalid,
  "aria-describedby": ariaDescribedby,
  hasError,
  description,
}: AddEditPostcodeInputProps) {
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [autocompleteInput, setAutocompleteInput] = useState<string>("");

  const { matchingPostcodes, searchPostcodeFailed } = useSelector(
    (state: RootState) => state.addEditPostcode
  );

  const dispatch = useDispatch();

  const isOptionEqualToValueCheck = (option: unknown, value: any): boolean => {
    if (option && value && (option as AutoSuggestOption).value && value.value) {
      return (
        (option as AutoSuggestOption).value.postcode === value.value.postcode &&
        (option as AutoSuggestOption).value.state === value.value.state &&
        (option as AutoSuggestOption).value.suburb === value.value.suburb
      );
    }

    return false;
  };

  useEffect(() => {
    dispatch(getCustomerProfileAction());
  }, [dispatch]);

  const debouncedAutocompleteInput = useDebounce(autocompleteInput, 300);

  const getPostcodeSuggestions = useCallback(
    (searchTerm: string) => {
      dispatch(getMatchingPostcodeSuggestions({ postcodeText: searchTerm }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      debouncedAutocompleteInput &&
      debouncedAutocompleteInput.length &&
      debouncedAutocompleteInput.length > AUTOCOMPLETE_INPUT_LIMIT
    ) {
      getPostcodeSuggestions(debouncedAutocompleteInput);
    }
  }, [debouncedAutocompleteInput, getPostcodeSuggestions]);

  const onAutocompleteInput = (newValue: string, _reason: string) => {
    setAutocompleteInput(newValue);
  };

  const autocompleteOnOpen = (_event: React.SyntheticEvent) => {
    setAutocompleteOpen(true);
  };

  const autocompleteOnClose = (_event: React.SyntheticEvent) => {
    setAutocompleteOpen(false);
  };

  const autocompleteNoResults = (
    <AutocompleteDropDownMessage>
      {autocompleteInput &&
      autocompleteInput.length >= AUTOCOMPLETE_INPUT_LIMIT ? (
        <React.Fragment>
          <WarningIcon />
          {searchPostcodeFailed
            ? "Something went wrong, please retry."
            : "No postcode matches found"}
        </React.Fragment>
      ) : (
        <React.Fragment>Enter postcode/suburb here</React.Fragment>
      )}
    </AutocompleteDropDownMessage>
  );

  return (
    <AutocompleteInputFieldRocket
      id={id}
      autoFocus={autoFocus}
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedby}
      data-testid="postcode-autocomplete-test"
      inputValue={autocompleteInput}
      isOptionEqualToValue={isOptionEqualToValueCheck}
      value={value}
      onChange={(_event, value, _reason) => {
        onChange(value as AutoSuggestOption);
      }}
      onClose={autocompleteOnClose}
      options={matchingPostcodes}
      onInputChange={(_event, newValue, reason) => {
        onAutocompleteInput(newValue, reason);
      }}
      onOpen={autocompleteOnOpen}
      open={autocompleteOpen}
      noOptionsText={autocompleteNoResults}
      hasError={hasError}
      descriptionText={description}
    />
  );
}
