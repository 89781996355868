import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProfilePage from "./components/profile/ProfilePage";
import { RootState } from "./store/Store";
import {
  AuthenticationStatus,
  checkAuthenticationFailed,
  toggleIsLoading,
} from "./store/check-authentication/CheckAuthenticationSlice";
import { Switch, useRouteMatch, useHistory } from "react-router-dom";
import { SentryRoute } from "./sentry.config";
import { ChangePasswordPage } from "./components/changePassword/ChangePasswordPage";
import ErrorPage from "./common/error/ErrorPage";
import { checkAuthenticationAction } from "./store/check-authentication/CheckAuthenticationActions";
import { AddEditMobileNumberPage } from "./components/addEditMobileNumber/AddEditMobileNumberPage";
import { VerifyMobileNumberPage } from "./components/verifyMobileNumber/VerifyMobileNumberPage";
import { VerificationCodeAcceptedPage } from "./components/verifyMobileNumber/VerificationCodeAcceptedPage";
import { Layout } from "./components/styled/Layout";
import { VerificationErrorPage } from "./components/verifyMobileNumber/VerificationErrorPage";
import { DeleteProfileResultPage } from "./components/profile/DeleteProfile/DeleteProfileResultPage";
import { useQuery } from "./common/hooks/useQuery";
import configuration from "./config/Configuration";
import AddressBookPage from "./components/addressBook/AddressBookPage";
import DeleteAccountPage from "./components/deleteAccount/DeleteAccountPage";
import PageLayout from "./PageLayout";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import { TeamMemberLinkPage } from "./components/teamMember/TeamMemberLinkPage";
import VerifyFlybuysAccountPage from "./components/flybuys/VerifyFlybuysAccountPage";
import MfaPreferencePage from "./components/profile/MfaSetting/MfaPreferencePage";
import FlybuysIntegrationPage from "./components/flybuys/FlybuysIntegrationPage";
import { AddEditPostcodePage } from "./components/addEditPostcode/AddEditPostcodePage";
import MarketingPreferencesPage from "./components/profile/MarketingPreferences/MarketingPreferencesPage";
import ReceiptPreferencesPage from "./components/profile/ReceiptPreferences/ReceiptPreferencesPage";
import UnsubscribePage from "./components/profile/Unsubscribe/UnsubscribePage";
import { getCustomerProfileAction } from "./store/customer-profile/CustomerProfileActions";
import useRocketEnabled from "./hooks/useRocketEnabled";
import ReceiptPreferencesPageRocket from "./components/profile/ReceiptPreferences/ReceiptPreferencesPageRocket";

function App() {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const status = useSelector((state: RootState) => state.authentication.status);
  const isRocketEnabled = useRocketEnabled();
  const isLoading = useSelector(
    (state: RootState) => state.authentication.isLoading
  );

  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  useEffect(() => {
    if (customerProfileType === "") {
      dispatch(getCustomerProfileAction());
    }
  }, [dispatch, customerProfileType]);

  const [backButtonUrl, setBackButtonUrl] = useState<string>();
  const queryParams = useQuery();
  useEffect(() => {
    const returnTo = queryParams.get("returnTo");
    if (returnTo) {
      setBackButtonUrl(returnTo);
    }
  }, [queryParams]);

  const shouldSkipAuthFlow = () => {
    const callbackPaths = ["/callback/flybuys", "/unsubscribe"];
    return callbackPaths.includes(history.location.pathname);
  };

  const errorPagePath = "/error";
  if (status === AuthenticationStatus.Unknown) {
    if (history.location.pathname === errorPagePath) {
      dispatch(checkAuthenticationFailed());
    } else if (shouldSkipAuthFlow()) {
      dispatch(toggleIsLoading(false));
    } else {
      dispatch(checkAuthenticationAction(history.location.pathname));
    }
  }

  useEffect(() => {
    if (isRocketEnabled) return;

    let currentPath = path;
    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (newLocation.pathname !== currentPath) {
          currentPath = newLocation.pathname;
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
            state: newLocation.state,
          });
        }
      } else if (action === "POP") {
        if (currentPath !== errorPagePath) {
          history.goForward();
        }
      }
    });
  }, [history, path, isRocketEnabled]);

  return (
    <Layout>
      <Switch>
        <ProtectedRoute path="/change-password">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <ChangePasswordPage backButtonUrl={backButtonUrl} />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/add-edit-mobile">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <AddEditMobileNumberPage backButtonUrl={backButtonUrl} />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/team-member-link-page">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <TeamMemberLinkPage />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/verify-mobile-number">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <VerifyMobileNumberPage backButtonUrl={backButtonUrl} />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/verification-code-accepted">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <VerificationCodeAcceptedPage backButtonUrl={backButtonUrl} />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/verify-mobile-error">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <VerificationErrorPage backButtonUrl={backButtonUrl} />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/delete-profile-result">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <DeleteProfileResultPage />
          </PageLayout>
        </ProtectedRoute>
        {configuration.enablePostcodeFeature && (
          <ProtectedRoute path="/add-edit-postcode">
            <PageLayout
              isNarrow
              backButtonUrl={backButtonUrl}
              isLoading={isLoading}
            >
              <AddEditPostcodePage backButtonUrl={backButtonUrl} />
            </PageLayout>
          </ProtectedRoute>
        )}
        <ProtectedRoute path="/address-book">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <AddressBookPage />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/change-security-level">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <MfaPreferencePage />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/delete-account">
          <DeleteAccountPage />
        </ProtectedRoute>
        <SentryRoute path="/callback/flybuys">
          <PageLayout isNarrow isLoading={isLoading}>
            <VerifyFlybuysAccountPage />
          </PageLayout>
        </SentryRoute>
        <SentryRoute path="/integration-flybuys">
          <PageLayout isNarrow isLoading={isLoading}>
            <FlybuysIntegrationPage />
          </PageLayout>
        </SentryRoute>
        <ProtectedRoute path="/marketing-preferences/:brand">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <MarketingPreferencesPage />
          </PageLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/receipt-preferences/:brand">
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            {isRocketEnabled ? (
              <ReceiptPreferencesPageRocket />
            ) : (
              <ReceiptPreferencesPage />
            )}
          </PageLayout>
        </ProtectedRoute>
        <SentryRoute path="/unsubscribe">
          <UnsubscribePage />
        </SentryRoute>
        <SentryRoute path={errorPagePath}>
          <PageLayout
            isNarrow
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <ErrorPage />
          </PageLayout>
        </SentryRoute>
        <ProtectedRoute path="/">
          <PageLayout
            isNarrow={isRocketEnabled}
            backButtonUrl={backButtonUrl}
            isLoading={isLoading}
          >
            <ProfilePage />
          </PageLayout>
        </ProtectedRoute>
      </Switch>
    </Layout>
  );
}

export default App;
