import React, { useEffect } from "react";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import Setting from "./ProfileSetting";
import * as features from "../../features";
import FirstNameProfileSetting from "./FirstNameProfileSetting";
import {
  customerProfilePageClosing,
  CustomerProfileState,
} from "../../store/customer-profile/CustomerProfileSlice";
import LastNameProfileSetting from "./LastNameProfileSetting";
import { DateOfBirthSetting } from "./DateOfBirthProfileSetting/DateOfBirthProfileSetting";
import { HomePhoneSetting } from "./HomePhoneProfileSetting/HomePhoneProfileSetting";
import { MobileNumberSetting } from "./MobileNumberProfileSetting/MobileNumberSetting";
import { useDispatch } from "react-redux";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { Grid, rdsMargin, rdsPadding, Row, Spacing } from "@coles/rocket";
import DeliveryAddressProfileSetting from "./DeliveryAddressProfileSetting";
import { PostcodeSetting } from "./Postcode/PostcodeSetting";
import { BodyTextRocket } from "ccp-common-ui-components";
import { ReadonlyProfileSettingRocket } from "./ProfileSettingRocket";
import { headers } from "../../common/styles/RocketColours";
export const passwordFillerLength: number = 8;

export const RocketSettings = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${headers.white900};
  ${rdsMargin.none.horizontal};
  ${rdsMargin.none.top};
  ${rdsMargin.s3.bottom};

  @media ${screenSize.mobile} {
    margin: 0 -${Spacing.s6}px ${Spacing.s3}px -${Spacing.s6}px;
  }
`;

export const SettingsDiv = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
`;

const SettingsGrid = styled(Grid).attrs({
  fluid: true,
  rowStyles: {
    marginTop: Spacing.s5,
    marginLeft: 0,
    marginRight: 0,
  },
  colStyles: { padding: 0 },
})`
  ${rdsPadding.none.all}
`;

export const Settings = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  const isRocketEnabled = useRocketEnabled();
  return isRocketEnabled ? (
    <SettingsGrid className={className}>{children}</SettingsGrid>
  ) : (
    <SettingsDiv className={className}>{children}</SettingsDiv>
  );
};

export const SettingsHeadingText = styled(BodyTextRocket).attrs({
  variant: "heading_400",
  as: "h2",
})`
  ${rdsMargin.s7.top}
`;

export const SettingsHeading = (props: any) => (
  <Row>
    <SettingsHeadingText {...props} />
  </Row>
);

export interface ProfileSettingsProps {
  customerProfile: CustomerProfileState;
  allowNameEditing?: boolean;
  refactoredMobileField?: boolean;
}

function ProfileSettings({
  allowNameEditing = features.allowNameEditing,
  customerProfile,
}: ProfileSettingsProps) {
  const { profileFields } = customerProfile;
  const { firstName, lastName, email } = profileFields;
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  useEffect(
    () => () => {
      dispatch(customerProfilePageClosing());
    },
    [dispatch]
  );

  return (
    <Settings>
      {isRocketEnabled && (
        <ReadonlyProfileSettingRocket
          name="Email address"
          value={email}
          id={"email"}
        />
      )}
      {isRocketEnabled && <SettingsHeading>Personal</SettingsHeading>}
      <FirstNameProfileSetting
        firstName={firstName}
        allowNameEditing={allowNameEditing}
      />
      <LastNameProfileSetting
        lastName={lastName}
        allowNameEditing={allowNameEditing}
      />
      {isRocketEnabled && <DateOfBirthSetting />}
      {!isRocketEnabled && (
        <Setting name="Email address" value={email} linkEnabled={true} />
      )}
      {isRocketEnabled && <SettingsHeading>Contact</SettingsHeading>}
      <MobileNumberSetting />
      <HomePhoneSetting />
      {!isRocketEnabled && <DateOfBirthSetting />}
      {isRocketEnabled && <PostcodeSetting />}
      {isRocketEnabled && <DeliveryAddressProfileSetting />}
    </Settings>
  );
}
export default ProfileSettings;
