import { useEffect, useState } from "react";
import { SubPageHeader } from "../styled/SubPageHeader";
import { Layout } from "../styled/Layout";
import {
  storeCustomerProfileAddresses,
  storeCustomerProfileAddressLinks,
  updateIsLoadingAddress,
} from "../../store/customer-profile-address/CustomerProfileAddressSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { getCustomerProfileAddressesAction } from "../../store/customer-profile-address/CustomerProfileAddressActions";
import { AuthenticationStatus } from "../../store/check-authentication/CheckAuthenticationSlice";
import AddressList from "./AddressList";
import { PageLoader } from "../common/PageLoader";
import {
  BodyTextRocket,
  Heading1Rocket,
  HideableDiv,
  Link,
  ThemedButton,
  UserCircleOutlineRocket,
} from "ccp-common-ui-components";
import { AddAddressButtonWhenHasAddresses } from "./add-address-form/AddAddressButtonWhenHasAddresses";
import EmptyAddresses from "./EmptyAddresses";
import { AddAddressBanner } from "./add-address-form/AddAddressBanner";
import { AddAddressBannerRocket } from "./add-address-form/AddAddressBannerRocket";
import {
  resetState,
  setAddressToBeEdited,
} from "../../store/add-address/AddAddressSlice";
import {
  AddressContainer,
  AddressContainerRocket,
  AddressHeader,
  ButtonContainer,
  CenteredButtonContainer,
  Description,
  DescriptionRocket,
  HeadingContainerRocket,
  RowAlignedDivRocket,
  SavedAddressesContainer,
} from "./AddressBookPageStyles";
import { queryCustomerProfileAddressesWithOffsetAndLimit } from "../../api/address/AddressesApi";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import AddressListRocket from "./AddressListRocket";

const AddressBookPage = () => {
  const dispatch = useDispatch();
  const { addresses, links, isLoadingAddresses } = useSelector(
    (state: RootState) => state.customerProfileAddress
  );

  const profileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );

  const isAddressSaved = useSelector(
    (state: RootState) => state.addAddress.isAddressSaved
  );

  const { saveAddressFailed, isAddingAddress } = useSelector(
    (state: RootState) => state.addAddress
  );

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const isRocketEnabled = useRocketEnabled();

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    const options: ScrollToOptions = {
      top: 0,
      left: 0,
    };
    window.scrollTo(options);
  }, []);

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(updateIsLoadingAddress(true));
      dispatch(getCustomerProfileAddressesAction());
      dispatch(setAddressToBeEdited(null));
    }
  }, [dispatch, authenticationStatus]);

  const returnToTop = () => {
    window.scrollTo(0, 0);
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    var loadedAddresses = await queryCustomerProfileAddressesWithOffsetAndLimit(
      links
    );
    var appendedAddresses = addresses.concat(loadedAddresses.data);

    dispatch(storeCustomerProfileAddresses(appendedAddresses));
    dispatch(storeCustomerProfileAddressLinks(loadedAddresses.links?.next));
    setIsLoadingMore(false);
  };

  const nonRocketLayout = (
    <Layout>
      <SubPageHeader className="sentry-unmask">My address book</SubPageHeader>
      <Description className="sentry-unmask">{`Here you can manage your delivery addresses. You can save a maximum of ${
        profileType === "business" ? "200" : "20"
      } addresses.`}</Description>

      <AddressContainer>
        <PageLoader isShown={isLoadingAddresses || isAddingAddress} />
        <HideableDiv isHidden={isLoadingAddresses || isAddingAddress}>
          <AddAddressBanner isShown={isAddressSaved || saveAddressFailed} />

          <SavedAddressesContainer>
            <AddressHeader className="sentry-unmask">
              My saved addresses
            </AddressHeader>
            <AddAddressButtonWhenHasAddresses />
          </SavedAddressesContainer>

          <AddressList addresses={addresses} />
          {!isRocketEnabled && <EmptyAddresses />}
          <ButtonContainer>
            <Link onClick={() => returnToTop()}>Return to top</Link>
            <Link href="/">Cancel</Link>
          </ButtonContainer>
          {links && !isLoadingMore && (
            <CenteredButtonContainer>
              <ThemedButton
                data-testid="load-more-button"
                onClick={() => loadMore()}
                className="sentry-unmask"
              >
                Load more
              </ThemedButton>
            </CenteredButtonContainer>
          )}
          {isLoadingMore && (
            <CenteredButtonContainer className="sentry-unmask">
              Loading ...
            </CenteredButtonContainer>
          )}
        </HideableDiv>
      </AddressContainer>
    </Layout>
  );

  const rocketLayout = (
    <Layout>
      <RowAlignedDivRocket>
        <UserCircleOutlineRocket size={30} />
        <HeadingContainerRocket>
          <Heading1Rocket
            data-testid="address-heading"
            className="sentry-unmask"
          >
            Your address book
          </Heading1Rocket>
        </HeadingContainerRocket>
      </RowAlignedDivRocket>
      <DescriptionRocket className="sentry-unmask">
        <BodyTextRocket variant="body_200">
          {`Manage your delivery addresses, you can save a maximum of ${
            profileType === "business" ? "200" : "20"
          }.`}
        </BodyTextRocket>
      </DescriptionRocket>

      <AddressContainerRocket>
        <PageLoader isShown={isLoadingAddresses || isAddingAddress} />
        <HideableDiv isHidden={isLoadingAddresses || isAddingAddress}>
          <AddAddressBannerRocket
            isShown={isAddressSaved || saveAddressFailed}
          />
          <SavedAddressesContainer>
            <AddressHeader className="sentry-unmask">
              Saved addresses
            </AddressHeader>
            <AddAddressButtonWhenHasAddresses />
          </SavedAddressesContainer>

          <AddressListRocket addresses={addresses} />
          {!isRocketEnabled && <EmptyAddresses />}
        </HideableDiv>
      </AddressContainerRocket>
    </Layout>
  );

  return isRocketEnabled ? rocketLayout : nonRocketLayout;
};

export default AddressBookPage;
