import { useSelector } from "react-redux";
import { ThemedMessageRocket } from "ccp-common-ui-components";
import { RootState } from "../../../store/Store";
import styled from "styled-components";
import { rdsPadding } from "@coles/rocket";

interface AddAddressBannerRocketProps {
  isShown: boolean;
}

const InputDiv = styled.div`
  ${rdsPadding.s6.bottom};
`;

export function AddAddressBannerRocket(props: AddAddressBannerRocketProps) {
  const { isAddressesLimitReached, saveSuccessMessage, saveFailedMessage } =
    useSelector((state: RootState) => state.addAddress);
  const profileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );
  const maximumAddressesAllowed =
    profileType?.toLowerCase() === "personal" ? 20 : 200;

  const message = saveSuccessMessage
    ? saveSuccessMessage
    : isAddressesLimitReached
    ? `Address not saved because it exceeds the limit of ${maximumAddressesAllowed}. Delete or edit an existing address to add new details.`
    : saveFailedMessage
    ? saveFailedMessage
    : "";

  const isError = isAddressesLimitReached || saveFailedMessage;

  return (
    <>
      {props.isShown && (
        <InputDiv data-testid="add-address-banner">
          <ThemedMessageRocket
            type={isError ? "error" : "success"}
            text={message}
            title=""
            isFullWidth={true}
          />
        </InputDiv>
      )}
    </>
  );
}
