import colesLogo from "../assets/logos/coles-logo.svg";
import styled from "styled-components";
import { screenSize } from "./styles/ScreenSizes";
import { BackButtonContainer } from "./BackButtonContainer";
import { rdsMargin, rdsPadding, Spacing } from "@coles/rocket";
import { BackButtonContainerRocket } from "./BackButtonContainerRocket";
import useRocketEnabled from "../hooks/useRocketEnabled";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px 0px 48px 0px;

  @media ${screenSize.tablet} {
    margin: 1px 0px 54px 0px;
  }

  @media ${screenSize.mobile} {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: -24px;
    margin-left: -${Spacing.s5}px;
    width: 100vw;
    background-color: white;
    box-shadow: 0px 2px 5px 0px rgb(182 182 182);
  }
`;

const Logo = styled.img`
  margin: 20px 0;
`;

const HeaderRocket = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;

  ${rdsMargin.s6.top};
  ${rdsMargin.s6.bottom};
  ${rdsMargin.none.left};
  ${rdsMargin.none.right};

  @media ${screenSize.tablet} {
    ${rdsPadding.s6.left};
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media ${screenSize.mobile} {
    z-index: 1;
  }
`;

const BackButtonWrapper = styled.div`
  ${rdsMargin.s6.top};
  ${rdsMargin.s6.bottom};
  margin-left: -${Spacing.s6}px;
  @media ${screenSize.mobile}, ${screenSize.tablet} {
    ${rdsMargin.s6.left};
    position: relative;
    z-index: 2;
  }
`;

function AppHeader({ backButtonUrl }: AppHeaderProps) {
  const isRocketEnabled = useRocketEnabled();

  const nonRocketHeader = (
    <Header>
      <Logo src={colesLogo} alt="Coles logo" width="123" height="23" />
      <BackButtonContainer returnTo={backButtonUrl} />
    </Header>
  );

  const rocketHeader = (
    <HeaderRocket>
      <BackButtonWrapper>
        <BackButtonContainerRocket returnTo={backButtonUrl} />
      </BackButtonWrapper>
      <LogoContainer>
        <Logo src={colesLogo} alt="Coles logo" width="102" height="32" />
      </LogoContainer>
    </HeaderRocket>
  );

  return isRocketEnabled ? rocketHeader : nonRocketHeader;
}

interface AppHeaderProps {
  backButtonUrl?: string | null;
}

export default AppHeader;
