import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../styled/Container";
import { InputDiv } from "../styled/InputDiv";
import { StyledButtonWrapper } from "../styled/StyledButtonWrapper";
import { SubPageHeader } from "../styled/SubPageHeader";
import { ThemedButton } from "../styled/ThemedButton";
import { screenSize } from "../../common/styles/ScreenSizes";
import {
  Heading1Rocket,
  HideableDiv,
  LinkButtonRocket,
  SecureLoginIconRocket,
  ThemedButtonRocket,
  ThemedMessageRocket,
} from "ccp-common-ui-components";
import useRocketEnabled from "../../hooks/useRocketEnabled";

import {
  resetVerifyCodeState,
  sendCodeAction,
  VerificationChannel,
  verifyCodeAction,
} from "../../store/verify-mobile-number/VerifyMobileNumberSlice";

import {
  isOneTimeCodeValid,
  maskValue,
  removeAllSpaces,
} from "../profile/Utils/PhoneUtils";
import { RootState } from "../../store/Store";
import { EditableOneTimeCode } from "./EditableOneTimeCode";
import { LineLabel } from "../styled/LineLabel";
import { Layout } from "../styled/Layout";
import { SettingButton } from "../styled/SettingButton";
import { headers } from "../../common/styles/RocketColours";
import { VerifyLaterBanner } from "./VerifyLaterBanner";
import { InformationContainer } from "../common/InformationContainer";
import { createSelector } from "reselect";
import { PageLoader } from "../common/PageLoader";
import configuration from "../../config/Configuration";
import { useIsPersonalCustomer } from "../profile/Utils/CustomerUtils";
import { RocketSettings } from "../profile/ProfileSettings";
import { rdsMargin, rdsPadding } from "@coles/rocket";
import { EditableOneTimeCodeRocket } from "./EditableOneTimeCodeRocket";

const addEditMyPhoneNumberText = "Add/Edit my phone number";
const didntReceiveATextText = "Didn't receive a text?";
const sendCodeInfoMessage =
  "We have sent the maximum number of codes to you. If you need another code please wait 5 minutes to re-send.";

const MaskedLabel = styled.label`
  font-weight: bold;
`;

const VerifyButtonContainer = styled(StyledButtonWrapper)`
  ${rdsPadding.s4.all};
`;

const VerifyButtonContainerRocket = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteredDiv = styled.div`
  text-align: center;
`;

export const VerifyButton = styled(ThemedButton)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

export const VerifyButtonRocket = styled(ThemedButtonRocket)`
  ${rdsMargin.s4.top};
  ${rdsPadding.s3.vertical};

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const VerifyLink = styled(Link)`
  font-weight: bold;
  color: ${headers.primary};
`;

const InformationMessageRocket = styled(ThemedMessageRocket)`
  width: 361px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
  ${rdsPadding.s7.right};
`;
const VerifyLaterLink = styled(SettingButton)<{ primary?: string }>`
  font-weight: normal;
  color: ${(props) => (props.primary ? headers.error : headers.black700)};
`;

const VerifyLaterLinkRocket = styled(ThemedButtonRocket)`
  ${rdsMargin.s3.top};
  ${rdsPadding.s3.vertical};

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

const ContainerRocket = styled(RocketSettings)`
  ${rdsMargin.s8.bottom};
  ${rdsPadding.s5.vertical};
  ${rdsPadding.s6.horizontal};
  position: relative;

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  ${rdsMargin.s2.bottom}
`;

const UnlockIconRocket = styled(SecureLoginIconRocket)`
  ${rdsMargin.s2.right}
`;

const selectIsLoading = createSelector(
  (state: RootState) => state.verifyMobileNumber.isSendingCode,
  (state: RootState) => state.verifyMobileNumber.isVerifyingCode,
  (state: RootState) => state.customerProfile.isVerifyingLater,
  (isSendingCode, isVerifyingCode, isVerifyingLater) =>
    isSendingCode || isVerifyingCode || isVerifyingLater
);

type FormData = {
  oneTimeCode: string;
};

interface VerifyMobileNumberPageProps {
  backButtonUrl: string | undefined;
}

export function VerifyMobileNumberPage(prop: VerifyMobileNumberPageProps) {
  const isRocketEnabled = useRocketEnabled();

  const oneTimeCodeInputName = "oneTimeCode";
  const [showBanner, setShowBanner] = useState(false);

  const mobile = useSelector(
    (state: RootState) => state.customerProfile.profileFields.mobile
  );

  const mfaPreference = useSelector(
    (state: RootState) => state.customerProfile.profileFields.mfaPreference
  );

  const verifyCodeErrorMessage = useSelector(
    (state: RootState) => state.verifyMobileNumber.verifyCodeErrorMessage
  );

  const sendCodeErrorMessage = useSelector(
    (state: RootState) => state.verifyMobileNumber.verificationErrorPageMessage
  );

  const isLoading = useSelector(selectIsLoading);
  const notYourMobileNumber = isRocketEnabled ? null : (
    <span>Not your mobile number? </span>
  );
  const maskedLabel = (
    <>
      <span>Enter the one-time code we sent to </span>
      <MaskedLabel>{maskValue(mobile)}. </MaskedLabel>
      {notYourMobileNumber}
    </>
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<FormData>();

  useEffect(() => {
    dispatch(resetVerifyCodeState());
  }, [dispatch]);

  const setOneTimeCodeValue = (value: string) => {
    const sanitizedValue = removeAllSpaces(value);
    const isNotEmpty = !sanitizedValue;
    const shouldValidate = isSubmitted && isNotEmpty;

    setValue(oneTimeCodeInputName, sanitizedValue, {
      shouldDirty: true,
      shouldValidate,
    });

    if (!shouldValidate) {
      clearErrors();
    }
  };

  const onSubmit = (data: FormData) => {
    dispatch(
      verifyCodeAction({
        verificationCode: data.oneTimeCode,
        channel: VerificationChannel.Sms,
      })
    );

    reset();
  };

  const sendCodeAgain = (e: React.MouseEvent) => {
    e.preventDefault();

    dispatch(
      sendCodeAction({
        mobile,
      })
    );
  };

  const verifyLater = (e: React.MouseEvent) => {
    if (
      mfaPreference?.toLocaleLowerCase() === "high" ||
      mfaPreference?.toLocaleLowerCase() === "medium"
    ) {
      setShowBanner(true);
      return;
    } else {
      setShowBanner(false);
      history.push("/");
    }
  };
  const isPersonalCustomer = useIsPersonalCustomer();

  const isSecurityPreferenceCustomerTypeFeatureFlagOn =
    configuration.securityPreferenceCustomerType;

  const verifyLaterLink = isRocketEnabled ? (
    <VerifyLaterLinkRocket
      onClick={verifyLater}
      data-testid="verify-later-link-rocket"
      className="sentry-unmask"
      label="Verify Later"
      variant="secondary"
      isFullWidth
    />
  ) : (
    <CenteredDiv>
      <VerifyLaterLink
        onClick={verifyLater}
        data-testid="verify-later-link"
        className="sentry-unmask"
      >
        Verify Later
      </VerifyLaterLink>
    </CenteredDiv>
  );

  const verifyLaterComponent =
    !isSecurityPreferenceCustomerTypeFeatureFlagOn || !isPersonalCustomer ? (
      <>{verifyLaterLink}</>
    ) : null;

  const sendCodeAgainLink = isRocketEnabled ? (
    <LinkButtonRocket
      onClick={sendCodeAgain}
      data-testid="send-code-again-button-rocket"
      className="sentry-unmask"
      size="medium"
      label="Resend code to my mobile"
    />
  ) : (
    <>
      <span>{didntReceiveATextText}</span>
      <SettingButton
        onClick={sendCodeAgain}
        data-testid="send-code-again-button"
        type="button"
        className="sentry-unmask"
      >
        Send Code Again
      </SettingButton>
    </>
  );

  const sendCodeAgainComponent = sendCodeErrorMessage ? (
    isRocketEnabled ? (
      <>
        <InformationMessageRocket
          data-testid="information-message-rocket"
          hasIcon={true}
          text={sendCodeInfoMessage}
          type="warning"
          title={""}
          isHidden={!sendCodeErrorMessage}
          isFullWidth
          hasCloseButton
        />
      </>
    ) : (
      <InformationContainer message={sendCodeInfoMessage} />
    )
  ) : (
    sendCodeAgainLink
  );

  const registerOptions = register(oneTimeCodeInputName, {
    required: true,
    validate: {
      validMobileNumber: (value) => isOneTimeCodeValid(value),
    },
    onChange: (e) => {
      setOneTimeCodeValue(e.target.value);
    },
  });
  const otcFieldRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    otcFieldRef.current?.focus();
  });

  const nonRocketContainer = (
    <Container>
      <PageLoader isShown={isLoading} />
      <HideableDiv isHidden={isLoading}>
        {showBanner && (
          <VerifyLaterBanner
            data-testid="verify-later-banner"
            backButtonUrl={prop.backButtonUrl}
          />
        )}
        <InputDiv role="status">
          {maskedLabel}
          <VerifyLink data-testid="add-edit-mobile-link" to="/add-edit-mobile">
            {addEditMyPhoneNumberText}
          </VerifyLink>
        </InputDiv>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="verify-mobile-number-form"
          noValidate
        >
          <InputDiv>
            <LineLabel htmlFor="editable-one-time-code-input">
              One time code
            </LineLabel>
            <EditableOneTimeCode
              autoComplete="off"
              id="editable-one-time-code-input"
              {...registerOptions}
              ref={(e) => {
                registerOptions.ref(e);
                otcFieldRef.current = e;
              }}
              errorMessage={
                verifyCodeErrorMessage
                  ? verifyCodeErrorMessage
                  : errors.oneTimeCode?.message
              }
            />
          </InputDiv>

          {sendCodeAgainComponent}

          <VerifyButtonContainer>
            <VerifyButton
              data-testid="save-button"
              type="submit"
              className="sentry-unmask"
            >
              Submit verification code
            </VerifyButton>
          </VerifyButtonContainer>
        </form>
        {verifyLaterComponent}
      </HideableDiv>
    </Container>
  );

  const rocketContainer = (
    <ContainerRocket>
      <HeaderContainer>
        <UnlockIconRocket />
        <Heading1Rocket className="sentry-unmask">
          Verify mobile number
        </Heading1Rocket>
      </HeaderContainer>
      <PageLoader isShown={isLoading} />
      <HideableDiv isHidden={isLoading}>
        {showBanner && (
          <VerifyLaterBanner
            data-testid="verify-later-banner-rocket"
            backButtonUrl={prop.backButtonUrl}
          />
        )}
        <InputDiv role="status">{maskedLabel}</InputDiv>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="verify-mobile-number-form-rocket"
          noValidate
        >
          <EditableOneTimeCodeRocket
            autoComplete="off"
            id="editable-one-time-code-input-rocket"
            {...registerOptions}
            ref={(e) => {
              registerOptions.ref(e);
              otcFieldRef.current = e;
            }}
            errorMessage={
              verifyCodeErrorMessage
                ? verifyCodeErrorMessage
                : errors.oneTimeCode?.message
            }
          />

          {sendCodeAgainComponent}

          <VerifyButtonContainerRocket>
            <VerifyButtonRocket
              data-testid="save-button-rocket"
              label="Verify"
              className="sentry-unmask"
              isFullWidth
              onClick={handleSubmit(onSubmit)}
            />
            {verifyLaterComponent}
          </VerifyButtonContainerRocket>
        </form>
      </HideableDiv>
    </ContainerRocket>
  );

  const container = isRocketEnabled ? rocketContainer : nonRocketContainer;

  const header = isRocketEnabled ? null : (
    <SubPageHeader className="sentry-unmask">
      Verify my phone number
    </SubPageHeader>
  );

  return (
    <Layout>
      {header}
      {container}
    </Layout>
  );
}
