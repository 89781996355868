import styled from "styled-components";
import { Settings } from "../../profile/ProfileSettings";
import { screenSize } from "../../../common/styles/ScreenSizes";
import {
  BodyTextRocket,
  Link,
  LinkRocket,
  ThemedButton,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { rdsMargin, rdsPadding, theme, Spacing } from "@coles/rocket";

export const MarketingPreferencesContainer = styled(Settings)`
  padding: 18px 25px;
  position: relative;
  @media ${screenSize.mobile} {
    margin: 0px;
  }
`;
export const MarketingPreferencesContainerRocket = styled.div`
  text-align: left;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const MarketingPreferencesDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 0.5rem;
  @media ${screenSize.mobile} {
    text-align: left;
  }
`;

export const MarketingPreferencesDescriptionRocket = styled(
  BodyTextRocket
).attrs({
  variant: "body_200",
})`
  position: relative;
  ${rdsMargin.s2.top};
  ${rdsMargin.s5.bottom};
`;

export const MarketingPreferencesButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 12px;
  align-items: center;
  border-top: 1px solid black;
  @media ${screenSize.mobile} {
    padding-left: 0px;
  }
`;

export const MarketingPreferencesButtonsRocket = styled.div`
  display: flex;
  flex-direction: column;
  ${rdsPadding.s3.top};
  ${rdsPadding.s3.right};
  ${rdsPadding.s3.bottom};
  ${rdsPadding.none.left};
  ${rdsMargin.s3.top};
  align-items: center;
  border-top: ${theme.rdsBorderWidth.default} solid;
  @media ${screenSize.mobile} {
    ${rdsPadding.none.left};
  }
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const CancelLinkRocket = styled(LinkRocket)`
  ${rdsMargin.s4.top};
  text-align: center;
`;

export const ThemedSavePerferencesButton = styled(ThemedButton)`
  width: 250px;
  margin-top: 10px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;
export const ThemedSavePerferencesButtonRocket = styled(ThemedButtonRocket)`
  ${rdsMargin.s3.top};
`;

export const StyledPrivacyParagraph = styled(BodyTextRocket).attrs({
  variant: "body_100",
})`
  color: ${theme.colorForeground.secondary};
  ${rdsPadding.none.top};
  margin-top: -${Spacing.s3}px;
  ${rdsMargin.s7.left};
  ${rdsPadding.s2.left};
`;

export const StyledParagraph = styled(BodyTextRocket).attrs({
  variant: "body_100",
})`
  color: ${theme.colorForeground.secondary};
  ${rdsPadding.none.top};
  margin-top: -${Spacing.s3}px;
  ${rdsPadding.s2.left};
  ${rdsMargin.s2.bottom};
  @media ${screenSize.mobile} {
    ${rdsPadding.s3.top};
    margin-top: -${Spacing.s2}px;
    ${rdsPadding.s4.left};
    ${rdsMargin.s6.left};
  }
`;
