import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { SettingLine } from "../../styled/SettingLine";
import { SettingLink } from "../../styled/SettingLink";
import { SettingButton } from "../../styled/SettingButton";
import { SpinnerContainer } from "../../styled/SpinnerContainer";
import {
  Container,
  FlybuysImage,
  FlybuysImageContainer,
  ValueMessage,
  LoadingText,
  flybuysImg,
} from "./FlybuysCardStyles";
import flybuysIcon from "../../../assets/icons/flybuys-blue-logo.svg";
import {
  setFlybuysUnlinkSuccess,
  setIsFlybuysLinkSuccess,
  setIsFlybuysUnlinkModalOpen,
} from "../../../store/flybuys/FlybuysSlice";
import { InlineLoader } from "../../../common/loader/InlineLoader";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { UpdateFailureMessage } from "../UpdateFailureMessage";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { Link, ThemedMessageRocket } from "ccp-common-ui-components";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import ProfileSettingRocket, {
  SettingFieldStatus,
} from "../ProfileSettingRocket";
import { IconFlybuysOutline, rdsMargin } from "@coles/rocket";
import styled from "styled-components";
import { Settings } from "../ProfileSettings";

const theme = createTheme({});

const IconFlybuysOutlineWithMargin = styled(IconFlybuysOutline)`
  ${rdsMargin.s2.right}
`;

const FlybuysPrivacyUrl =
  "https://experience.flybuys.com.au/policies/privacy-policy/";

const UnlinkButtonGroup = ({
  isUnlinkRequestLoading,
  onUnlinkFlybuysClicked,
}: {
  isUnlinkRequestLoading: boolean;
  onUnlinkFlybuysClicked(): void;
}) => {
  if (isUnlinkRequestLoading) {
    return (
      <SpinnerContainer>
        <InlineLoader />
        <LoadingText>Updating...</LoadingText>
      </SpinnerContainer>
    );
  }

  return (
    <SettingLink>
      <SettingButton onClick={onUnlinkFlybuysClicked}>
        Unlink my flybuys
      </SettingButton>
    </SettingLink>
  );
};

const ErrorMessageRocket = () => {
  return (
    <ThemedMessageRocket
      type="error"
      text={
        "Something's gone wrong on our end and we can't access Flybuys information right now. Please try again later."
      }
    />
  );
};
const LinkSuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setIsFlybuysLinkSuccess(undefined));
    };
  }, [dispatch]);
  return <ThemedMessageRocket type="success" text="Flybuys linked" />;
};
const LinkUnsuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setIsFlybuysLinkSuccess(undefined));
    };
  }, [dispatch]);
  return (
    <ThemedMessageRocket
      type="error"
      text="Something went wrong and we can't link your Flybuys right now. Please try again later."
    />
  );
};
const UnlinkSuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setFlybuysUnlinkSuccess(undefined));
    };
  }, [dispatch]);
  return (
    <ThemedMessageRocket
      type="success"
      text="Your flybuys account has been successfully unlinked."
    />
  );
};
const UnlinkUnsuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setFlybuysUnlinkSuccess(undefined));
    };
  }, [dispatch]);
  return (
    <ThemedMessageRocket
      type="error"
      text="Something went wrong and we can't unlink your Flybuys right now. Please try again later."
    />
  );
};

const FlybuysCard = () => {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  useEffect(() => {
    return () => {
      dispatch(setFlybuysUnlinkSuccess(undefined));
      dispatch(setIsFlybuysLinkSuccess(undefined));
    };
  }, [dispatch]);

  const { flybuys, isLoadingLoyaltyAccount } = useSelector(
    (state: RootState) => state.loyaltyAccounts
  );

  const {
    isUnlinkRequestLoading,
    isFlybuysUnlinkSuccess,
    isFlybuysLinkSuccess,
  } = useSelector((state: RootState) => state.flybuys);

  const [Message, setMessage] = useState<React.FunctionComponent | undefined>();
  useEffect(() => {
    if (flybuys.hasError) {
      return setMessage(() => ErrorMessageRocket);
    }
    if (isFlybuysLinkSuccess !== undefined) {
      return isFlybuysLinkSuccess
        ? setMessage(() => LinkSuccessfulMessage)
        : setMessage(() => LinkUnsuccessfulMessage);
    }
    setMessage(undefined);
  }, []);
  useEffect(() => {
    if (isFlybuysUnlinkSuccess !== undefined) {
      return isFlybuysUnlinkSuccess
        ? setMessage(() => UnlinkSuccessfulMessage)
        : setMessage(() => UnlinkUnsuccessfulMessage);
    }
  }, [isFlybuysUnlinkSuccess]);
  const onUnlinkFlybuysClicked = () => {
    dispatch(setIsFlybuysUnlinkModalOpen(true));
  };

  const openFlybuysLink = () => {
    window.location.href = flybuys ? flybuys.linkFlybuysUrl : "";
  };

  const isFlybuysLinked = flybuys?.memberId !== "";

  const errorSettingLine = (
    <SettingLine>
      <FlybuysImageContainer>
        <FlybuysImage src={flybuysIcon} alt="flybuys" />
      </FlybuysImageContainer>
      <ValueMessage>
        Something's gone wrong on our end and we can't access Flybuys
        information right now. Please try again later.
      </ValueMessage>
    </SettingLine>
  );

  const UnlinkFlybuysStatusMessage = () => {
    if (isFlybuysUnlinkSuccess === undefined) return <></>;
    return isFlybuysUnlinkSuccess ? (
      <UpdateSuccessMessage text="Your flybuys account has been successfully unlinked." />
    ) : (
      <UpdateFailureMessage>
        Something went wrong and we can't unlink your Flybuys right now. Please
        try again later.
      </UpdateFailureMessage>
    );
  };

  const LinkFlybuysStatusMessage = () => {
    return isFlybuysLinkSuccess ? (
      <UpdateSuccessMessage text="Flybuys linked" />
    ) : (
      <></>
    );
  };

  const SkeletonGroup = (
    <SettingLine>
      <Skeleton
        data-testid={`flybuys-card-linked-image`}
        variant="rectangular"
        width={flybuysImg.width}
        height={flybuysImg.height}
      />
      <ValueMessage>
        <Skeleton
          style={{ marginLeft: "24px" }}
          data-testid={`flybuys-card-linked-message`}
          variant="text"
        />
      </ValueMessage>
    </SettingLine>
  );

  const FlybuysLinked = (
    <SettingLine>
      <FlybuysImageContainer>
        <FlybuysImage src={flybuysIcon} alt="flybuys" />
      </FlybuysImageContainer>
      <ValueMessage>
        {flybuys.memberId}
        <UnlinkFlybuysStatusMessage />
        <LinkFlybuysStatusMessage />
      </ValueMessage>
      <UnlinkButtonGroup
        isUnlinkRequestLoading={isUnlinkRequestLoading}
        onUnlinkFlybuysClicked={onUnlinkFlybuysClicked}
      />
    </SettingLine>
  );

  const FlybuysUnlinked = (
    <SettingLine>
      <FlybuysImageContainer>
        <FlybuysImage src={flybuysIcon} alt="flybuys" />
      </FlybuysImageContainer>
      <ValueMessage data-testid="no-flybuys-message">
        No flybuys account is linked.
        <br /> <br />
        Once linked, you will also be able to collect Flybuys points on in-store
        purchases at Coles Supermarkets by scanning your Coles barcode.
        <br /> <br />
        By scanning your Coles barcode, Flybuys will also share your email
        address and personal information with Coles in accordance with your
        email share settings and the{" "}
        <Link href={FlybuysPrivacyUrl} target="_blank">
          Flybuys privacy policy
        </Link>
        .
        <UnlinkFlybuysStatusMessage />
        <LinkFlybuysStatusMessage />
      </ValueMessage>
      {flybuys.linkFlybuysUrl && (
        <SettingLink>
          <SettingButton onClick={openFlybuysLink}>
            Link my flybuys
          </SettingButton>
        </SettingLink>
      )}
    </SettingLine>
  );

  return isRocketEnabled ? (
    <Settings>
      <ProfileSettingRocket
        name="Flybuys"
        NameComponent={() => (
          <>
            <IconFlybuysOutlineWithMargin />
            Flybuys
          </>
        )}
        handleEdit={
          flybuys.memberId
            ? onUnlinkFlybuysClicked
            : flybuys.linkFlybuysUrl
            ? openFlybuysLink
            : undefined
        }
        id="link-flybuys"
        link={
          flybuys.memberId
            ? "Unlink flybuys"
            : !!flybuys.linkFlybuysUrl
            ? "Link flybuys"
            : undefined
        }
        value={
          flybuys.hasError
            ? " "
            : flybuys.memberId ||
              "Collect Flybuys points and see instore purchases"
        }
        linkEnabled
        MessageComponent={Message}
        status={
          isUnlinkRequestLoading
            ? SettingFieldStatus.PendingUpdate
            : SettingFieldStatus.Readable
        }
      />
    </Settings>
  ) : (
    <MuiThemeProvider theme={theme}>
      <Container data-testid="flybuys-container">
        {isLoadingLoyaltyAccount && SkeletonGroup}
        {!isLoadingLoyaltyAccount && flybuys.hasError && errorSettingLine}
        {!isLoadingLoyaltyAccount &&
          !flybuys.hasError &&
          isFlybuysLinked &&
          FlybuysLinked}
        {!isLoadingLoyaltyAccount &&
          !flybuys.hasError &&
          !isFlybuysLinked &&
          FlybuysUnlinked}
      </Container>
    </MuiThemeProvider>
  );
};

export default FlybuysCard;
