import {
  BodyTextRocket,
  HideableDiv,
  Link,
  ThemedButton,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import { rdsMargin, rdsPadding } from "@coles/rocket";

export const VerifyButtonContainer = styled.div`
  padding: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const VerifyButtonContainerRocket = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const FormHelpText = styled.p`
  margin-top: 0;
`;

export const ThemedButtonRocketStyled = styled(ThemedButtonRocket)`
  ${rdsMargin.s6.top}
`;

export const FormHelpTextRocket = styled(BodyTextRocket).attrs({
  variant: "body_200",
})`
  ${rdsPadding.s4.bottom};
`;
export const LineLabelRocket = styled(BodyTextRocket).attrs({
  variant: "body_300_bold",
})``;

export const VerifyButton = styled(ThemedButton)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

export const StyledHideableDiv = styled(HideableDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const ContainerRocket = styled.div`
  ${rdsMargin.s8.bottom};
  ${rdsMargin.s6.horizontal};
  ${rdsPadding.s6.horizontal};
  position: relative;
  @media ${screenSize.mobile} {
    margin: 0px;
  }
`;
