import styled from "styled-components";
import Setting from "./ProfileSetting";
import { Link, useHistory } from "react-router-dom";
import { screenSize } from "../../common/styles/ScreenSizes";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import ProfileSettingRocket from "./ProfileSettingRocket";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
  }
`;

const DeliveryAddressProfileSetting = () => {
  const isRocketEnabled = useRocketEnabled();
  const { push } = useHistory();

  const goToAddressEditPage = () => {
    push("/address-book");
  };

  return isRocketEnabled ? (
    <ProfileSettingRocket
      name="Delivery addresses"
      handleEdit={goToAddressEditPage}
      handleSave={goToAddressEditPage}
      id="delivery-address"
      linkEnabled
      link="Manage"
      value={" "}
    />
  ) : (
    <Container>
      <Setting
        name="Delivery address"
        value="Manage / edit your delivery address from your address book"
        link={<Link to="/address-book">View address book</Link>}
        linkEnabled={true}
      />
    </Container>
  );
};

export default DeliveryAddressProfileSetting;
