import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { MfaDisplay } from "./MfaDisplay";
import { Link, useHistory } from "react-router-dom";
import { SettingLink } from "../../styled/SettingLink";
import { useIsPersonalCustomer } from "../Utils/CustomerUtils";
import configuration from "../../../config/Configuration";
import { MfaDisplayStatic } from "./MfaDisplayStatic";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import ProfileSettingRocket, {
  ReadonlyProfileSettingRocket,
} from "../ProfileSettingRocket";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { ThemedMessageRocket } from "ccp-common-ui-components";

const getErrorMessageRocket = (errorMessage: string) => () => {
  return <ThemedMessageRocket type="error" text={errorMessage} />;
};

export function MfaSetting() {
  const isPersonalCustomer = useIsPersonalCustomer();
  const isRocketEnabled = useRocketEnabled();
  const { push } = useHistory();
  const [MfaMessage, setMfaMessage] = useState<
    React.FunctionComponent<{}> | undefined
  >();

  const isSecurityPreferenceCustomerTypeFeatureFlagOn =
    configuration.securityPreferenceCustomerType;

  const showChangeSecurityPreference =
    !isSecurityPreferenceCustomerTypeFeatureFlagOn || !isPersonalCustomer;

  const errorMessage = useSelector(
    (state: RootState) => state.customerProfile.mfaPreferenceErrorMessage
  );

  const goToSecurityLevelPage = () => {
    push("/change-security-level");
  };

  useEffect(() => {
    if (errorMessage) {
      setMfaMessage(() => getErrorMessageRocket(errorMessage));
    } else {
      setMfaMessage(undefined);
    }
  }, [errorMessage]);

  return isRocketEnabled ? (
    showChangeSecurityPreference ? (
      <ProfileSettingRocket
        id="mfa-settings"
        handleEdit={goToSecurityLevelPage}
        handleSave={goToSecurityLevelPage}
        link="Change"
        linkEnabled
        DisplayComponent={MfaDisplay}
        MessageComponent={MfaMessage}
        name="Security level preference"
      />
    ) : (
      <ReadonlyProfileSettingRocket
        id="mfa-settings"
        value="One-time codes are sent to your mobile."
        name="Multi-factor authentication"
      />
    )
  ) : (
    <SettingLine>
      <SettingName id="radio-group-label">
        {showChangeSecurityPreference
          ? "Security level preference:"
          : "Multi-factor authentication:"}
      </SettingName>
      {showChangeSecurityPreference ? <MfaDisplay /> : <MfaDisplayStatic />}
      <SettingLink>
        {showChangeSecurityPreference && (
          <Link to="/change-security-level">Change preference</Link>
        )}
      </SettingLink>
    </SettingLine>
  );
}
