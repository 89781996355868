import React from "react";
import styled from "styled-components";
import { UseFormRegisterReturn } from "react-hook-form";
import { ErrorText, InputFieldRocket } from "ccp-common-ui-components";
import { InputFieldProps } from "../../common/form/InputField";
import { screenSize } from "../../common/styles/ScreenSizes";
import { SettingInput } from "../styled/SettingInput";
import { MessageContainer } from "../styled/MessageContainer";
import { invalidTeamMemberNumberErrorMessage } from "../../common/constants/InfoMessages";
import useRocketEnabled from "../../hooks/useRocketEnabled";

export type TeamMemberNumberInputProps = InputFieldProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type TeamMemberNumberProps = TeamMemberNumberInputProps & {
  setTeamMemberNumber?: (value: string) => void;
  hasValidationError: boolean;
  successfullyLinked?: boolean;
} & UseFormRegisterReturn;

const TeamMemberNumberContainer = styled.div`
  flex: 1 1 auto;
  order: 2;
  width: 100%;
  @media ${screenSize.mobile} {
    order: 2;
  }
`;

const TeamMemberNumberInput = styled(SettingInput)`
  max-width: 100%;
`;

export const TeamMemberNumber = React.forwardRef(
  (
    props: TeamMemberNumberInputProps & TeamMemberNumberProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const isRocketEnabled = useRocketEnabled();

    const hasError =
      !!props.hasValidationError ||
      (props.successfullyLinked !== undefined && !props?.successfullyLinked);

    const errorTextId = "team-member-number-error";

    const teamMemberNumberCurrent = (
      <TeamMemberNumberContainer data-testid="team-member-number">
        <TeamMemberNumberInput
          id="team-member-number"
          data-testid="team-member-number-input"
          hasError={hasError}
          displaySuccessIcon={false}
          type="text"
          aria-invalid={hasError}
          aria-describedby={errorTextId}
          required
          {...props}
          ref={ref}
        />
        {hasError && (
          <MessageContainer>
            <ErrorText id={errorTextId} data-testid="error-text">
              {invalidTeamMemberNumberErrorMessage}
            </ErrorText>
          </MessageContainer>
        )}
      </TeamMemberNumberContainer>
    );

    const { size, ...rest } = props;

    const teamMemberNumberRocket = (
      <TeamMemberNumberContainer data-testid="team-member-number-rocket">
        <InputFieldRocket
          id="team-member-number-input-rocket"
          data-testid="team-member-number-input-rocket"
          hasError={hasError}
          labelText="mycoles card number"
          errorText={hasError ? invalidTeamMemberNumberErrorMessage : undefined}
          aria-invalid={hasError}
          aria-describedby={errorTextId}
          descriptionText="Enter your mycoles card number to receive a discount with every shop."
          required
          {...rest}
          ref={ref}
        />
      </TeamMemberNumberContainer>
    );

    const teamMemberNumberComponent = isRocketEnabled
      ? teamMemberNumberRocket
      : teamMemberNumberCurrent;

    return <>{teamMemberNumberComponent}</>;
  }
);
