import styled from "styled-components";
import { screenSize } from "../../../common/styles/ScreenSizes";
import { CustomerProfileAddressFields } from "../../../store/customer-profile-address/CustomerProfileAddressSlice";
import { SettingButton } from "../../styled/SettingButton";
import {
  setFormVisibility,
  clearAddressNotifications,
} from "../../../store/add-address/AddAddressSlice";
import { useDispatch } from "react-redux";
import AddressContactsRocket from "../AddressContactsRocket";
import { MapPinOutlineIconRocket } from "ccp-common-ui-components";
import { rdsPadding, theme } from "@coles/rocket";

export const AddressSettings = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-self: flex-start;

  ${rdsPadding.none.horizontal};
  ${rdsPadding.s6.vertical};
  border-bottom: ${theme.rdsBorderWidth.default} solid
    ${theme.colorBorder.tertiary};

  width: 100%;

  @media ${screenSize.mobile}, ${screenSize.tablet} {
    width: 100%;
  }
`;

const EditAddressLinkContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  ${rdsPadding.s6.vertical};
  ${rdsPadding.s6.left};
  ${rdsPadding.none.right};
`;

const StreetAddress = styled.div`
  display: flex;
  @media ${screenSize.mobile} {
    flex: 1 0 100%;
  }
`;

const MapIconRocketContainer = styled.div`
  width: 24px;
`;

const ExtendedAddress = styled.div`
  ${rdsPadding.s6.left};
`;

const trimToLength = (value: string, length: number) => {
  return value.substring(0, length);
};

const formatAddressLines = (addressLines: string[]) => {
  return addressLines
    .filter((address) => address && address.length > 0)
    .map((address) => trimToLength(address, 64))
    .join(", ");
};

interface AddressCardProps {
  address: CustomerProfileAddressFields;
  editAddressId: string | null;
  setEditAddress: (arg0: CustomerProfileAddressFields) => void | null;
}

export function AddressCardRocket(props: AddressCardProps) {
  const dispatch = useDispatch();
  const editButton = props.editAddressId === null && (
    <EditAddressLinkContainer>
      <SettingButton
        onClick={() => {
          props.setEditAddress(props.address);
          dispatch(setFormVisibility(true));
          dispatch(clearAddressNotifications());
        }}
      >
        Edit
      </SettingButton>
    </EditAddressLinkContainer>
  );

  return props.editAddressId !== props.address.id ? (
    <AddressSettings>
      {editButton}

      <StreetAddress>
        <MapIconRocketContainer>
          <MapPinOutlineIconRocket />
        </MapIconRocketContainer>
        <strong>
          {formatAddressLines([
            props.address.addressLine1,
            props.address.addressLine2,
            props.address.addressLine3,
          ])}
        </strong>
      </StreetAddress>

      <ExtendedAddress>
        {trimToLength(props.address.city, 64)},{" "}
        {trimToLength(props.address.state, 4)}{" "}
        {trimToLength(props.address.postcode, 4)}
      </ExtendedAddress>

      <AddressContactsRocket
        recipientFirstName={trimToLength(props.address.recipientFirstName, 31)}
        recipientLastName={trimToLength(props.address.recipientLastName, 31)}
        contacts={props.address.contacts}
      />
    </AddressSettings>
  ) : (
    <></>
  );
}
