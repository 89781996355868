import { useDispatch, useSelector } from "react-redux";
import { updateLastNameAction } from "../../store/update-last-name/UpdateLastNameActions";
import { RootState } from "../../store/Store";
import { UpdateFailureMessage } from "./UpdateFailureMessage";
import InlineEditProfileSetting from "./InlineEditProfileSetting";
import ReadonlyProfileSetting from "./ReadonlyProfileSetting";
import { UpdateSuccessMessage } from "./UpdateSuccessMessage";
import ValidationFailureMessage from "./ValidationFailureMessage";
import { asyncOperationReset } from "../../store/async-operation/AsyncOperationSlice";
import { ReadonlyProfileSettingRocket } from "./ProfileSettingRocket";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { ThemedMessageRocket } from "ccp-common-ui-components";

export interface LastNameProfileSettingProps {
  lastName: string;
  allowNameEditing: boolean;
}

const isValid = (value: string) => {
  return value.length <= 31 && /^[a-z '-]+$/i.test(value);
};

const sanitize = (value: string) => {
  return `${value}`.trim().replace(/ {2,}/g, " ");
};

const ValidationFailedMessage = (props: any) => {
  const isRocketEnabled = useRocketEnabled();
  if (isRocketEnabled) {
    return (
      <ThemedMessageRocket
        type="error"
        text="Your family name cannot include special characters or symbols. Please try again."
      />
    );
  }
  return (
    <ValidationFailureMessage
      {...props}
      text={
        "Your last name cannot include special characters or symbols. Please try again."
      }
      id="last-name-error"
    />
  );
};

const UpdateSuccessfulMessage = (props: any) => {
  const isRocketEnabled = useRocketEnabled();
  if (isRocketEnabled) {
    return (
      <ThemedMessageRocket
        type="success"
        text="Your family name has been updated."
      />
    );
  }
  return (
    <UpdateSuccessMessage {...props} text="Your last name has been updated." />
  );
};

const UpdateFailedMessage = (props: any) => {
  const isRocketEnabled = useRocketEnabled();
  if (isRocketEnabled) {
    return (
      <ThemedMessageRocket
        type="error"
        text="We weren’t able to update your family name. Please try again later."
      />
    );
  }
  return (
    <UpdateFailureMessage {...props}>
      We weren’t able to update your last name. Please try again later.
    </UpdateFailureMessage>
  );
};

function LastNameProfileSetting(props: LastNameProfileSettingProps) {
  const dispatch = useDispatch();
  const updateStatus = useSelector(
    (state: RootState) => state.asyncOperation.updateLastName.status
  );
  const isRocketEnabled = useRocketEnabled();

  const onEdit = () => {
    dispatch(asyncOperationReset("updateLastName"));
  };

  const onSave = (newLastName: string) => {
    dispatch(updateLastNameAction({ lastName: newLastName }));
  };

  return props.allowNameEditing ? (
    <InlineEditProfileSetting
      name={isRocketEnabled ? "Family name" : "Last name"}
      value={props.lastName}
      id="last-name"
      startEditLabel="Edit last name"
      saveLabel="Save last name"
      onSave={onSave}
      onEdit={onEdit}
      saveStatus={updateStatus}
      isValid={isValid}
      sanitize={sanitize}
      InvalidMessage={ValidationFailedMessage}
      SuccessMessage={UpdateSuccessfulMessage}
      FailureMessage={UpdateFailedMessage}
    />
  ) : isRocketEnabled ? (
    <ReadonlyProfileSettingRocket
      name="Family name"
      value={props.lastName}
      id="last-name"
    />
  ) : (
    <ReadonlyProfileSetting
      name="Last name"
      displayValue={props.lastName}
      id="last-name"
    />
  );
}

export default LastNameProfileSetting;
