import { calculatePasswordStrengthScore } from "../../../common/password-strength-checker/PasswordStrengthCalculator";

const newPasswordDoesNotMeetValidationErrorText =
  "Your password isn’t secure enough. " +
  "Try again - it must be at least 8 characters and include both letters (uppercase or lowercase) and numbers (0-9)";
const newPasswordsDoNotMatchErrorText =
  "The new passwords you have entered do not match. Please " +
  "re-enter them and try again.";

export enum MaxLength {
  Name = 31,
  Mobile = 10,
  Password = 512,
}
export function validateNewPassword(
  password: string,
  firstName: string,
  lastName: string,
  email: string,
  mobile: string
): boolean | string {
  if (!password) {
    return false;
  }
  const isValid = newPasswordMeetsMinimumRequirements(
    password,
    firstName,
    lastName,
    email,
    mobile
  );

  return isValid || newPasswordDoesNotMeetValidationErrorText;
}

export function newPasswordMatches(
  newPassword: string,
  confirmNewPassword: string
): boolean | string {
  return newPassword === confirmNewPassword || newPasswordsDoNotMatchErrorText;
}

function hasRepeatingChars(input: string, maxCount: number): boolean {
  const freq: { [char: string]: number } = {};

  for (let i = 0; i < input.length; i++) {
    const character = input.charAt(i);
    if (freq[character]) {
      freq[character]++;
      if (freq[character] > maxCount) {
        return true;
      }
    } else {
      freq[character] = 1;
    }
  }

  return false;
}

function hasSameCharacterInSequence(input: string, maxCount: number): boolean {
  let lastChar = "";
  let lastCharCount = 1;

  for (let i = 0; i < input.length; i++) {
    let character = input.charAt(i);

    if (character === lastChar) {
      lastCharCount++;
    } else {
      lastCharCount = 1;
    }

    if (lastCharCount > maxCount) {
      return true;
    }

    lastChar = character;
  }

  return false;
}

function newPasswordMeetsMinimumRequirements(
  password: string,
  firstName: string,
  lastName: string,
  email: string,
  mobile: string
): boolean {
  const passwordLengthIsWithinValidRange =
    password.length >= 8 && password.length <= 512;
  const passwordHasNumber = !!password.match(/[0-9]/);
  const passwordHasLetter = !!password.match(/[A-Za-z]/);
  const passwordStrengthScore = calculatePasswordStrengthScore(
    password,
    firstName,
    lastName,
    email,
    mobile
  );
  const scoreIsNegative = passwordStrengthScore < 0;

  return (
    passwordLengthIsWithinValidRange &&
    passwordHasNumber &&
    passwordHasLetter &&
    !scoreIsNegative &&
    !hasRepeatingChars(password, 32) &&
    !hasSameCharacterInSequence(password, 20)
  );
}
