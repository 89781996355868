import { InputFieldRocket } from "ccp-common-ui-components";
import React, { useEffect } from "react";

export interface IAddAddressInputRocket {
  id: string;
  name: string;
  value: string;
  defaultValue: string;
  setValue: (name: any, value: any) => void;
  errorMessage?: string;
  errorTextId: string;
  revalidate: (name: any) => void;
}

export function AddAddressInputRocket(props: IAddAddressInputRocket) {
  // Explicitly exempted from exhaustive-deps rule
  // Fix this when we come through and purge the form validation
  useEffect(() => {
    props.setValue(props.name, props.value);
  }, [props.name, props.setValue]); // eslint-disable-line

  const hasError = !!props.errorMessage;

  return (
    <>
      <InputFieldRocket
        id={props.id}
        data-testid={props.id}
        name={props.name}
        defaultValue={props.value}
        hasError={hasError}
        onChange={(e) => {
          props.setValue(props.name, e.target.value);
          props.revalidate(props.name);
        }}
        autoComplete="off"
        errorText={props.errorMessage}
      />
    </>
  );
}
