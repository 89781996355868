import { useDispatch, useSelector } from "react-redux";
import {
  TermsAndConditionsModal,
  TermsAndConditionsModalRocket,
} from "ccp-common-ui-components";
import { RootState } from "../../store/Store";
import { setShowTermsAndConditions } from "../../store/user-acceptances/UserAcceptancesSlice";
import useRocketEnabled from "../../hooks/useRocketEnabled";

export function TermsAndConditionsModalContainer() {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  const { showTermsAndConditions } = useSelector(
    (state: RootState) => state.userAcceptances
  );

  const closeModal = () => {
    dispatch(setShowTermsAndConditions(false));
  };

  return isRocketEnabled ? (
    <TermsAndConditionsModalRocket
      titleText="Coles Group Account Terms &amp; Conditions"
      isOpen={showTermsAndConditions}
      closeHandler={closeModal}
      showFooterButton
      footerButtonText="Close"
    />
  ) : (
    <TermsAndConditionsModal
      isOpen={showTermsAndConditions}
      shouldCloseOnEsc={true}
      onRequestClose={() => closeModal()}
    />
  );
}
