import { useEffect } from "react";
import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";
import ProfileSettings, { SettingsHeading } from "./ProfileSettings";
import PreferredStoreProfileSetting from "./PreferredStoreProfileSetting";
import { enablePreferredStore as isPreferredStoreEnabled } from "../../features";
import {
  CustomerProfileState,
  updateIsLoadingCustomerProfile,
} from "../../store/customer-profile/CustomerProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/Store";
import { AuthenticationStatus } from "../../store/check-authentication/CheckAuthenticationSlice";
import { getCustomerProfileAction } from "../../store/customer-profile/CustomerProfileActions";
import configuration from "../../config/Configuration";
import SecuritySettings from "./SecuritySettings";
import { endMfaPreferenceFlow } from "../../store/verify-mobile-number/VerifyMobileNumberSlice";
import {
  HideableDiv,
  Heading1Rocket,
  BodyTextRocket,
  UserCircleOutlineRocket,
} from "ccp-common-ui-components";
import { PageLoader } from "../common/PageLoader";
import { DeleteProfileBanner } from "../../store/delete-customer-profile/DeleteProfileBanner";
import DeliveryAddressProfileSetting from "./DeliveryAddressProfileSetting";
import TeamMemberProfile from "./TeamMemberProfile/TeamMemberProfile";
import { TeamMemberNumberBanner } from "../teamMember/TeamMemberNumberBanner";
import FlybuysCard from "./Flybuys/FlybuysCard";
import { MfaPreferenceUpdateSuccessBanner } from "./MfaSetting/MfaPreferenceUpdateSuccessBanner";
import { MfaPreferenceUpdateFailedBanner } from "./MfaSetting/MfaPreferenceUpdateFailedBanner";
import { PostcodeSetting } from "./Postcode/PostcodeSetting";
import { getLoyaltyAccountsAction } from "../../store/loyalty-accounts/LoyaltyAccountsActions";
import MarketingPreferences from "./MarketingPreferences";
import ReceiptPreferences from "./ReceiptPreferences";
import { getCustomerProfilePreferencesAction } from "../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import { profilePageImpression } from "../../common/utils/tracking/profilePageTracking";
import { getQueryParameter } from "../../common/utils/NavigationHelpers";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import {
  rdsPadding,
  Grid,
  IconEmailOutline,
  IconLinkOutline,
  rdsMargin,
  Spacing,
  theme,
  rdsBorderWidth,
} from "@coles/rocket";

const Title = styled.h1`
  color: ${headers.black900};
  margin: 0 0 36px 0;
  font-family: "Avant Garde", "century gothic", arial, sans-serif;
  line-height: 45px;
  text-align: center;

  @media ${screenSize.mobile} {
    line-height: 35px;
  }
`;

const SettingsSectionHeading = styled(Heading1Rocket)`
  ${rdsMargin.s4.bottom};
  ${rdsPadding.s8.top};
  ${rdsMargin.s7.top};
  display: flex;
  gap: ${Spacing.s3}px;
  justify-content: start;
  align-items: center;
  border-top: ${theme.rdsBorderWidth.default} solid
    ${theme.colorBorder.tertiary};
`;

const SettingsSectionHeadingWithLowerMargin = styled(SettingsSectionHeading)`
  ${rdsMargin.s8.bottom};
`;

const SettingsContainerRocket = styled(Grid).attrs({
  fluid: true,
  rowStyles: {
    marginLeft: 0,
    marginRight: 0,
  },
  colStyles: { padding: 0 },
})`
  ${rdsPadding.none.horizontal};
  ${rdsPadding.s9.bottom};
`;

const PageContainer = styled.div<{ isRocket: boolean }>`
  align-self: ${(props) => (props.isRocket ? "flex-start" : "center")};
  width: 100%;
  height: 100%;
`;

const SubTitle = styled.h2`
  color: #000a12;
  font-family: "Avant Garde";
  line-height: 32px;
  margin: 0 0 12px 0;
  text-align: center;

  @media ${screenSize.mobile} {
    line-height: 32px;
    word-break: break-word;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ProfileContainer = styled.div`
  align-self: center;
  border-top: 2px #c7c7c7 solid;
  padding: 18px 0 32px 0;
  width: 100%;
`;

const ProfileHeader = styled.h3`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-weight: 500;
  line-height: 27px;
  margin: 0 0 24px 0;

  @media ${screenSize.mobile} {
    line-height: 32px;
  }
`;

const ProfileHeaders = styled.h3`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-weight: 500;
  line-height: 27px;
  margin: 24px 0;

  @media ${screenSize.mobile} {
    line-height: 32px;
  }
`;

const RowAlignedDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${Spacing.s3}px;
`;

const HeaderSectionRocket = styled.div<{ isLoading: Boolean }>`
  ${(props) => (props.isLoading ? "" : rdsMargin.s8.bottom)};
  visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.s3}px;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: ${rdsBorderWidth.default} solid ${theme.colorBorder.tertiary};
  border-bottom: ${rdsBorderWidth.default} solid ${theme.colorBorder.tertiary};
  ${rdsPadding.s8.vertical};
`;

export interface ProfilePageProps {
  preferredStoreEnabled?: boolean;
  customerProfile: CustomerProfileState;
}

export default function ProfilePage() {
  const isRocketEnabled = useRocketEnabled();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );
  const { isLoadingCustomerProfile } = customerProfile;
  const { firstName } = customerProfile.profileFields;
  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );
  const { pendingDeletion } = customerProfile.profileFields;
  const { marketingPreferences, receiptPreferences } = useSelector(
    (state: RootState) => state.customerProfilePreferences
  );

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(updateIsLoadingCustomerProfile(true));
      dispatch(getCustomerProfileAction());
      dispatch(getCustomerProfilePreferencesAction());
      dispatch(getLoyaltyAccountsAction({ returnTo: "", action: "" }));
      dispatch(endMfaPreferenceFlow());
    }
  }, [dispatch, authenticationStatus, history]);

  const returnTo =
    getQueryParameter("returnTo") ||
    `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    if (
      customerProfile.profileFields.email &&
      marketingPreferences?.length > 0
    ) {
      profilePageImpression(
        returnTo,
        customerProfile.profileFields,
        marketingPreferences
      );
    }
  }, [
    returnTo,
    customerProfile.profileFields.email,
    marketingPreferences,
    customerProfile.profileFields,
  ]);

  return (
    <PageContainer isRocket={isRocketEnabled}>
      {isRocketEnabled ? (
        <HeaderSectionRocket
          isLoading={isLoadingCustomerProfile}
          data-testid="welcome-message"
        >
          <RowAlignedDiv>
            <UserCircleOutlineRocket size={30} />
            <Heading1Rocket data-testid="account-heading">
              Your account
            </Heading1Rocket>
          </RowAlignedDiv>
          <BodyTextRocket variant="body_200">
            Welcome {firstName}! You can manage your information and preferences
            here.
          </BodyTextRocket>
        </HeaderSectionRocket>
      ) : (
        <>
          <Title className="sentry-unmask">My Coles Group profile</Title>
          <HideableDiv
            isHidden={isLoadingCustomerProfile}
            data-testid="welcome-message"
          >
            <SubTitle>Welcome {firstName}!</SubTitle>
          </HideableDiv>
          <Description className="sentry-unmask">
            Here you can view and update the information stored in your profile.
          </Description>
        </>
      )}
      {isRocketEnabled ? (
        isLoadingCustomerProfile ? (
          <PageLoader isShown />
        ) : (
          <SettingsContainerRocket>
            <DeleteProfileBanner isShown={!!pendingDeletion} />
            <MfaPreferenceUpdateSuccessBanner />
            <MfaPreferenceUpdateFailedBanner />
            <ProfileSettings customerProfile={customerProfile} />
            {isPreferredStoreEnabled && <PreferredStoreProfileSetting />}
            <>
              <SettingsHeading>Security</SettingsHeading>
              <SecuritySettings />
            </>
            <SettingsSectionHeading>
              <IconEmailOutline />
              Preferences
            </SettingsSectionHeading>
            {marketingPreferences &&
            marketingPreferences.length &&
            marketingPreferences.length > 0 ? (
              <>
                <SettingsHeading>Marketing preferences</SettingsHeading>
                <MarketingPreferences preferences={marketingPreferences} />
              </>
            ) : null}
            {receiptPreferences &&
            receiptPreferences.length &&
            receiptPreferences.length > 0 ? (
              <>
                <SettingsHeading>Receipt preferences</SettingsHeading>
                <ReceiptPreferences preferences={receiptPreferences} />
              </>
            ) : null}
            <SettingsSectionHeadingWithLowerMargin>
              <IconLinkOutline />
              Linked accounts
            </SettingsSectionHeadingWithLowerMargin>
            <FlybuysCard />
            <TeamMemberProfile />
          </SettingsContainerRocket>
        )
      ) : (
        <ProfileContainer>
          <PageLoader isShown={isLoadingCustomerProfile} />
          <HideableDiv
            isHidden={isLoadingCustomerProfile}
            data-testid="hideable"
          >
            <DeleteProfileBanner isShown={!!pendingDeletion} />
            <MfaPreferenceUpdateSuccessBanner />
            <MfaPreferenceUpdateFailedBanner />
            <TeamMemberNumberBanner />
            <ProfileHeader className="sentry-unmask">
              My profile information
            </ProfileHeader>
            <ProfileSettings customerProfile={customerProfile} />
            {isPreferredStoreEnabled && <PreferredStoreProfileSetting />}
            {configuration.enablePostcodeFeature && <PostcodeSetting />}
            <DeliveryAddressProfileSetting />
            {marketingPreferences &&
            marketingPreferences.length &&
            marketingPreferences.length > 0 ? (
              <>
                <ProfileHeaders>Marketing preferences</ProfileHeaders>
                <MarketingPreferences preferences={marketingPreferences} />
              </>
            ) : null}
            {receiptPreferences &&
            receiptPreferences.length &&
            receiptPreferences.length > 0 ? (
              <>
                <ProfileHeaders>Receipt preferences</ProfileHeaders>
                <ReceiptPreferences preferences={receiptPreferences} />
              </>
            ) : null}
            <>
              <ProfileHeaders className="sentry-unmask">
                Security information
              </ProfileHeaders>
              <SecuritySettings />
            </>
            <>
              <ProfileHeaders
                data-testid="linked-accounts-header"
                className="sentry-unmask"
              >
                Linked accounts
              </ProfileHeaders>
              <FlybuysCard />
              <TeamMemberProfile />
            </>
          </HideableDiv>
        </ProfileContainer>
      )}
    </PageContainer>
  );
}
