import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  margin: 0px;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`;
