import { Loader, LoaderRocket } from "ccp-common-ui-components";
import { ScreenReaderText } from "../styled/ScreenReaderText";
import useRocketEnabled from "../../hooks/useRocketEnabled";

export interface PageLoaderProps {
  isShown?: boolean;
  screenReaderMessage?: string;
}

export function PageLoader({
  isShown,
  screenReaderMessage = "Loading...",
}: PageLoaderProps) {
  const isRocketEnabled = useRocketEnabled();
  return (
    <>
      {isShown && (
        <>
          {isRocketEnabled ? (
            <LoaderRocket isShown={true} />
          ) : (
            <Loader isShown={true} />
          )}
          <ScreenReaderText role="status">
            {screenReaderMessage}
          </ScreenReaderText>
        </>
      )}
    </>
  );
}
