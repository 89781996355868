import { FunctionComponent, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { RootState } from "../../../store/Store";

import { SettingLink } from "../../styled/SettingLink";
import { MobileNumberDisplay } from "./MobileNumberDisplay";
import editIcon from "../../../assets/icons/edit.svg";
import styled from "styled-components";
import { useFeatureFlags } from "../../../common/feature-flags/UseFeatureFlags";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import ProfileSettingRocket from "../ProfileSettingRocket";
import { ThemedMessageRocket } from "ccp-common-ui-components";
import { resetEditMobileNumber } from "../../../store/verify-mobile-number/VerifyMobileNumberSlice";

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

const DeleteSuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetEditMobileNumber());
  }, [dispatch]);
  return (
    <ThemedMessageRocket
      data-testid="success-notification"
      type="success"
      text="Your mobile number has been successfully deleted."
    />
  );
};
const DeleteUnsuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetEditMobileNumber());
  }, [dispatch]);
  return (
    <ThemedMessageRocket
      data-testid="error-notification"
      type="error"
      text="Your mobile number could not be deleted. Please try again later."
    />
  );
};

export function MobileNumberSetting() {
  const { isBcpFeatureEnabled } = useFeatureFlags();
  const isRocketEnabled = useRocketEnabled();
  const [Message, setMessage] = useState<FunctionComponent | undefined>();
  const { mobile: originalMobileNumber } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const isDeleteMobileSuccess = useSelector(
    (state: RootState) => state.verifyMobileNumber.isDeleteMobileSuccess
  );

  const buttonText = originalMobileNumber
    ? "Edit mobile number"
    : "Add mobile number";

  useEffect(() => {
    const getMessageComponent = () => {
      if (isDeleteMobileSuccess !== undefined) {
        return isDeleteMobileSuccess
          ? DeleteSuccessfulMessage
          : DeleteUnsuccessfulMessage;
      }
      return undefined;
    };
    setMessage(() => getMessageComponent());
  }, [isDeleteMobileSuccess]);

  return isRocketEnabled ? (
    <ProfileSettingRocket
      name="Mobile number"
      value={originalMobileNumber}
      editLink="/add-edit-mobile"
      id="mobile-number"
      linkEnabled={!isBcpFeatureEnabled}
      link={isBcpFeatureEnabled ? "" : originalMobileNumber ? "Edit" : "Add"}
      MessageComponent={Message}
    />
  ) : (
    <SettingLine>
      <SettingName>Mobile number:</SettingName>
      <MobileNumberDisplay />
      {!isBcpFeatureEnabled && (
        <SettingLink>
          {originalMobileNumber && (
            <EditIcon
              data-testid="edit-icon"
              src={editIcon}
              alt=""
              aria-hidden
            />
          )}
          <Link data-testid="add-edit-mobile-link" to="/add-edit-mobile">
            {buttonText}
          </Link>
        </SettingLink>
      )}
    </SettingLine>
  );
}
