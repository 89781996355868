import styled from "styled-components";
import { screenSize } from "../../../common/styles/ScreenSizes";
import { RocketSettings, Settings } from "../../profile/ProfileSettings";
import { Link } from "ccp-common-ui-components";
import { headers } from "../../../common/styles/RocketColours";
import { UpdateFailureMessage } from "../UpdateFailureMessage";

export const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 18px;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  order: 2;
  @media ${screenSize.mobile} {
    order: 3;
    flex: 1 1 100%;
  }
`;

export const RadioButtonError = styled.div`
  padding-left: 29px;
  padding-bottom: 1em;
  font-size: 14px;
  color: ${headers.primary};
`;

export const StyledUpdateFailureMessage = styled(UpdateFailureMessage)`
  display: inline;
  padding-top: 0;
`;

export const ChangeMfaContainerRocket = styled(RocketSettings)`
  margin-bottom: 50px;
  padding: 18px 25px;
  position: relative;
`;

export const ChangeMfaContainer = styled(Settings)`
  margin-bottom: 50px;
  padding: 18px 25px;
  position: relative;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
`;
