import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IconCircle } from "../../common/IconCircle";
import { StyledButtonWrapper } from "../../styled/StyledButtonWrapper";
import {
  CenteredHeader,
  CenteredHeaderRocket,
  CenteredParagraph,
  CenteredParagraphRocket,
  InfoContainer,
  InfoContainerRocket,
  InfoPageThemedButton,
  InfoPageThemedButtonRocket,
  StyledButtonWrapperRocket,
} from "../../styled/InfoPage";
import { continueMessage } from "../../../common/constants/InfoMessages";
import { Layout } from "../../styled/Layout";
import { redirect } from "../../../common/utils/NavigationHelpers";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import useRocketEnabled from "../../../hooks/useRocketEnabled";

export function MfaPreferenceSuccessPage() {
  const isRocketEnabled = useRocketEnabled();

  const headingRef = useRef<HTMLHeadingElement>(null);
  const history = useHistory();

  function handleClick() {
    const linkValue = getReturnToLink();
    if (linkValue !== "/") {
      return redirect(linkValue);
    }
    history.push("/");
  }

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  const nonRocketLayout = (
    <Layout>
      <InfoContainer>
        <IconCircle decorative={true} />
        <CenteredHeader
          tabIndex={-1}
          ref={headingRef}
          className="sentry-unmask"
        >
          Security preference updated
        </CenteredHeader>
        <CenteredParagraph className="sentry-unmask">
          Your security preference has been updated successfully.
        </CenteredParagraph>
        <StyledButtonWrapper>
          <InfoPageThemedButton
            onClick={() => handleClick()}
            className="sentry-unmask"
          >
            {continueMessage}
          </InfoPageThemedButton>
        </StyledButtonWrapper>
      </InfoContainer>
    </Layout>
  );

  const rocketComponent = (
    <Layout>
      <InfoContainerRocket>
        <IconCircle decorative={true} />
        <CenteredHeaderRocket
          tabIndex={-1}
          autoFocus
          className="sentry-unmask"
          data-testid="centered-header-rocket"
        >
          Security preference updated
        </CenteredHeaderRocket>

        <CenteredParagraphRocket className="sentry-unmask">
          Your security preference has been updated successfully.
        </CenteredParagraphRocket>

        <StyledButtonWrapperRocket>
          <InfoPageThemedButtonRocket
            isFullWidth
            onClick={() => handleClick()}
            data-testid="info-page-themed-button-rocket"
            className="sentry-unmask"
            label={continueMessage}
          />
        </StyledButtonWrapperRocket>
      </InfoContainerRocket>
    </Layout>
  );

  return isRocketEnabled ? rocketComponent : nonRocketLayout;
}
