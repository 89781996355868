import styled from "styled-components";
import { InputField, InputFieldProps } from "../../common/form/InputField";
import { screenSize } from "../../common/styles/ScreenSizes";
import {
  InputFieldRocket,
  InputFieldRocketProps,
} from "ccp-common-ui-components";
import { forwardRef, InputHTMLAttributes } from "react";

const SettingInputField = styled(InputField)`
  max-width: 80%;
  @media ${screenSize.mobile} {
    max-width: 100%;
  }
`;

export const SettingInput = forwardRef(
  (
    props: InputFieldProps & InputHTMLAttributes<HTMLInputElement>,
    ref: React.Ref<any>
  ) => {
    return <SettingInputField {...props} ref={ref} />;
  }
);

export const SettingInputRocket = forwardRef(
  (
    props: InputFieldRocketProps & { id: string; autoFocus?: boolean },
    ref: React.Ref<any>
  ) => {
    return <InputFieldRocket {...props} ref={ref} />;
  }
);
