import { Layout } from "../../styled/Layout";
import {
  BodyTextRocket,
  Heading1Rocket,
  InputFieldRocket,
  ThemedButtonRocket,
  ThemedSwitchRocket,
} from "ccp-common-ui-components";
import {
  CancelLink,
  ReceiptPreferencesButtonsRocket,
  HeaderSectionPreferencesRocket,
  HeadingContainerPreferencesRocket,
  ReceiptPreferencesContainerRocket,
  ContainerRocket,
  LineLabelRocketStyled,
  ReceiptPreferencesCardContainerRocket,
  FlyBuysCardIcon,
  ThemedButtonRocketCardStyled,
  IconCardDiv,
  ButtonCardDiv,
  BodyTextCardDiv,
  JustifyCenterCardDiv,
  LineLabelRocketCardStyled,
  SwitchRocketLabelDiv,
  StyledOL,
  RowAlignedDiv,
  LinkRocketStyled,
  NoIconCardDiv,
  BodyEmailInputCardDiv,
} from "./ReceiptPreferencesPageStyles";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import {
  IconBrandColesBadge,
  IconReceiptOutline,
  IconWrapper,
} from "@coles/rocket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import configuration from "../../../config/Configuration";
import flyBuysIcon from "../../../assets/icons/IconFlybuys.svg";
import { useIsBusinessCustomer } from "../Utils/CustomerUtils";
import { ChangeEvent, useEffect, useState } from "react";
import { AuthenticationStatus } from "../../../store/check-authentication/CheckAuthenticationSlice";
import { getCustomerProfileAction } from "../../../store/customer-profile/CustomerProfileActions";
import { getLoyaltyAccountsAction } from "../../../store/loyalty-accounts/LoyaltyAccountsActions";
import { useHistory, useParams } from "react-router-dom";
import { getCustomerProfilePreferencesAction } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import {
  digitalReceipts,
  email,
  instoreContent,
  mapPreferences,
  onlineContent,
  paper,
  preferredemail,
} from "../Utils/PreferencesUtils";
import { useForm } from "react-hook-form";
import { updateCustomerProfilePreferences } from "../../../api/preferences/CustomerProfilePreferencesApi";
import { ReceiptPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { PageLoader } from "../../common/PageLoader";
import { validateEmail } from "./validation/validation";
import {
  createCustomerProfileEmailContact,
  deleteCustomerProfileEmailContact,
  updateCustomerProfileEmailContact,
} from "../../../api/contacts/CustomerProfileContactsApi";
import { IconCircle } from "../../common/IconCircle";
import {
  CenteredHeaderRocket,
  CenteredParagraphRocket,
  StyledButtonWrapperRocket,
  InfoPageThemedButtonRocket,
} from "../../styled/InfoPage";
import { redirect } from "../../../common/utils/NavigationHelpers";

export interface EmailFormInputs {
  email: string;
}

const ReceiptPreferencesPageRocket = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { brand } = useParams<{ brand?: string }>();
  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm<EmailFormInputs>();

  const emailErrorText =
    errors.email && errors.email.message ? errors.email.message : "";

  const { receiptPreferences, isLoadingPreferences, secondaryEmail } =
    useSelector((state: RootState) => state.customerProfilePreferences);

  const secondaryEmailValue = secondaryEmail?.emailValue;

  const [isInstorePaperPreference, setIsInstorePaperPreference] =
    useState(false);
  const [isOnlinePreference, setOnlinePreference] = useState(false);
  const [isSecondaryEmailPreference, setSecondaryEmailPreference] =
    useState(false);

  const [isInstorePaperPreferencesChanged, setInstorePaperPreferencesChanged] =
    useState<boolean>(false);
  const [isOnlinePreferencesChanged, setOnlinePreferencesChanged] =
    useState<boolean>(false);

  const [updatePreferencesSuccess, setUpdatePreferencesSuccess] =
    useState<boolean>(false);
  const [updatePreferencesFailed, setUpdatePreferencesFailed] =
    useState<boolean>(false);
  const isUpdatePreferencesAny =
    updatePreferencesSuccess || updatePreferencesFailed;

  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );

  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const { isLoadingCustomerProfile } = customerProfile;

  const isBusinessCustomer = useIsBusinessCustomer();

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );
  const { flybuys, isLoadingLoyaltyAccount } = useSelector(
    (state: RootState) => state.loyaltyAccounts
  );

  const openFlybuysLink = () => {
    window.location.href = flybuys ? flybuys.linkFlybuysUrl : "";
  };

  const isFlybuysLinked = flybuys?.memberId !== "";

  const handleSuccessOrErrorClick = () => {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  };

  const { location } = window;
  let flyBuysReturnToUrl = encodeURIComponent(location.href);

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(getCustomerProfileAction());
      dispatch(
        getLoyaltyAccountsAction({ returnTo: flyBuysReturnToUrl, action: "" })
      );
    }
  }, [dispatch, authenticationStatus, history]);

  useEffect(() => {
    dispatch(getCustomerProfilePreferencesAction());
    if (customerProfileType === "") {
      dispatch(getCustomerProfileAction());
    }
  }, [dispatch, customerProfileType]);

  useEffect(() => {
    var instorePaperPreferenceValue =
      receiptPreferences.find(
        (pref) =>
          pref.content === instoreContent &&
          pref.mode === paper &&
          pref.brand === brand
      )?.value ?? false;
    setIsInstorePaperPreference(!instorePaperPreferenceValue);

    var onlinePreferenceValue =
      receiptPreferences.find(
        (pref) =>
          pref.content === onlineContent &&
          pref.mode === email &&
          pref.brand === brand
      )?.value ?? false;
    setOnlinePreference(onlinePreferenceValue);
  }, [receiptPreferences, brand]);

  useEffect(() => {
    setSecondaryEmailPreference(secondaryEmail?.id !== "");
    if (secondaryEmailValue && secondaryEmailValue !== "") {
      setValue("email", secondaryEmailValue);
    }
  }, [secondaryEmail, secondaryEmailValue, setValue]);

  useEffect(() => {
    if (isSecondaryEmailPreference === true) {
      register("email", {
        required: "Enter a valid email e.g. anna@email.com",
      });
    } else {
      unregister("email");
      clearErrors("email");
      setValue("email", "");
    }
  }, [isSecondaryEmailPreference, clearErrors, register, setValue, unregister]);

  const handleIsInstorePaperPreferenceChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (isInstorePaperPreference !== event.target.checked) {
      setInstorePaperPreferencesChanged(true);
    }

    setIsInstorePaperPreference(event.target.checked);
  };

  const handleIsOnlinePreferenceChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (isOnlinePreference !== event.target.checked) {
      setOnlinePreferencesChanged(true);
    }

    if (event.target.checked === false) {
      setSecondaryEmailPreference(false);
      setValue("email", "");
    }

    setOnlinePreference(event.target.checked);
  };

  const handleIsSecondaryEmailPreferenceChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === false) {
      setValue("email", "");
    }

    setSecondaryEmailPreference(event.target.checked);
  };

  const showLoader =
    isLoadingPreferences || isLoadingCustomerProfile || isLoadingLoyaltyAccount;

  const cardFlyBuysNotLinked = (
    <>
      <ReceiptPreferencesCardContainerRocket>
        <JustifyCenterCardDiv>
          <IconCardDiv>
            <FlyBuysCardIcon src={flyBuysIcon} alt="alert" />
          </IconCardDiv>
          <BodyTextCardDiv>
            <LineLabelRocketCardStyled className="sentry-unmask">
              Digital receipts only
            </LineLabelRocketCardStyled>
            <BodyTextRocket>
              To enable digital receipts, link your Flybuys and Coles account.
            </BodyTextRocket>
          </BodyTextCardDiv>
          <ButtonCardDiv>
            <ThemedButtonRocketCardStyled
              data-testid="link-flybuys-button-rocket"
              className="sentry-unmask"
              variant="primary"
              size="small"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                openFlybuysLink();
              }}
            >
              Link Flybuys
            </ThemedButtonRocketCardStyled>
          </ButtonCardDiv>
        </JustifyCenterCardDiv>
      </ReceiptPreferencesCardContainerRocket>
    </>
  );

  const cardFlyBuysLinkedInStorePreference = (
    <>
      <ReceiptPreferencesCardContainerRocket>
        <JustifyCenterCardDiv>
          <IconCardDiv>
            <IconBrandColesBadge height="40" width="40" />
          </IconCardDiv>
          <BodyTextCardDiv>
            <LineLabelRocketCardStyled className="sentry-unmask">
              Digital receipts only
            </LineLabelRocketCardStyled>
            <BodyTextRocket>
              Skip printing, get digital receipts only for instore shopping.
            </BodyTextRocket>
          </BodyTextCardDiv>
          <ButtonCardDiv>
            <ThemedSwitchRocket
              checked={isInstorePaperPreference}
              onChange={handleIsInstorePaperPreferenceChange}
            />
            <SwitchRocketLabelDiv>
              {isInstorePaperPreference === true ? "On" : "Off"}
            </SwitchRocketLabelDiv>
          </ButtonCardDiv>
        </JustifyCenterCardDiv>
      </ReceiptPreferencesCardContainerRocket>
    </>
  );

  const cardSendCopyToAnotherEmail = (
    <>
      <JustifyCenterCardDiv>
        <NoIconCardDiv>
          <div></div>
        </NoIconCardDiv>
        <BodyTextCardDiv>
          <LineLabelRocketCardStyled className="sentry-unmask">
            Send a copy to another email address
          </LineLabelRocketCardStyled>
          <BodyTextRocket>
            Send receipts to another email as well. Your accountant’s email, for
            example.
          </BodyTextRocket>
        </BodyTextCardDiv>
        <ButtonCardDiv>
          <ThemedSwitchRocket
            checked={isSecondaryEmailPreference}
            onChange={handleIsSecondaryEmailPreferenceChange}
          />
          <SwitchRocketLabelDiv>
            {isSecondaryEmailPreference === true ? "On" : "Off"}
          </SwitchRocketLabelDiv>
        </ButtonCardDiv>
      </JustifyCenterCardDiv>
      {isSecondaryEmailPreference && (
        <>
          <JustifyCenterCardDiv>
            <BodyEmailInputCardDiv>
              <InputFieldRocket
                id="secondary-email-input"
                data-testid="secondary-email-input"
                type="email"
                aria-invalid={!!errors.email}
                aria-describedby="email-error information-collection"
                autoComplete="email"
                hasError={!!errors.email}
                errorText={emailErrorText}
                {...register("email", {
                  validate: validateEmail,
                })}
              />
            </BodyEmailInputCardDiv>
          </JustifyCenterCardDiv>
        </>
      )}
    </>
  );

  const cardOnlinePreference = (
    <>
      {isBusinessCustomer && (
        <>
          <LineLabelRocketCardStyled className="sentry-unmask">
            Online
          </LineLabelRocketCardStyled>
          <ReceiptPreferencesCardContainerRocket>
            <JustifyCenterCardDiv>
              <IconCardDiv>
                <IconBrandColesBadge height="40" width="40" />
              </IconCardDiv>
              <BodyTextCardDiv>
                <LineLabelRocketCardStyled className="sentry-unmask">
                  Email receipts
                </LineLabelRocketCardStyled>
                <BodyTextRocket>
                  Send a copy of your online orders to the email on this
                  account.
                </BodyTextRocket>
              </BodyTextCardDiv>
              <ButtonCardDiv>
                <ThemedSwitchRocket
                  checked={isOnlinePreference}
                  onChange={handleIsOnlinePreferenceChange}
                />
                <SwitchRocketLabelDiv>
                  {isOnlinePreference === true ? "On" : "Off"}
                </SwitchRocketLabelDiv>
              </ButtonCardDiv>
            </JustifyCenterCardDiv>
            {isOnlinePreference && cardSendCopyToAnotherEmail}
          </ReceiptPreferencesCardContainerRocket>
        </>
      )}
    </>
  );

  const getPayloadForInstorePreferences = () => {
    const payloadForInstorePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(
        receiptPreferences.filter(
          (pref) =>
            pref.brand === brand &&
            pref.content === instoreContent &&
            pref.mode === paper
        )
      )
    );
    if (isInstorePaperPreferencesChanged) {
      payloadForInstorePreferences[0].value = !isInstorePaperPreference;
    }

    return payloadForInstorePreferences;
  };

  const getPayloadForOnlinePreferences = () => {
    const payloadForOnlinePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(
        receiptPreferences.filter(
          (pref) =>
            pref.brand === brand &&
            pref.content === onlineContent &&
            pref.mode === email
        )
      )
    );
    if (isOnlinePreferencesChanged) {
      payloadForOnlinePreferences[0].value = isOnlinePreference;
    }

    return payloadForOnlinePreferences;
  };

  const submitChangedPreferences = async (data: EmailFormInputs) => {
    const payload = mapPreferences([
      ...(isInstorePaperPreferencesChanged
        ? getPayloadForInstorePreferences()
        : []),
      ...(isOnlinePreferencesChanged ? getPayloadForOnlinePreferences() : []),
    ]);

    let isSecondaryEmailChanged = secondaryEmail.emailValue !== data.email;
    let isSecondaryEmailAlreadyExist = secondaryEmail.id !== "";

    let isContactDeleted = false;
    let isEmailUpdateSuccessful = true;

    if (isSecondaryEmailChanged && isSecondaryEmailAlreadyExist) {
      if (data.email) {
        try {
          isEmailUpdateSuccessful = await updateCustomerProfileEmailContact(
            secondaryEmail.id,
            {
              Email: data.email,
            }
          );
        } catch {
          isEmailUpdateSuccessful = false;
        }
      } else {
        isContactDeleted = true;

        payload.push({
          group: digitalReceipts,
          name: preferredemail,
          value: "",
        });
      }
    }

    if (isSecondaryEmailChanged && !isSecondaryEmailAlreadyExist) {
      if (data.email) {
        const emailCreateResult = await createCustomerProfileEmailContact({
          Email: data.email,
        });

        payload.push({
          group: digitalReceipts,
          name: preferredemail,
          value: emailCreateResult.id,
        });
      }
    }

    let isPreferencesUpdateSuccessful = true;

    if (payload.length > 0) {
      try {
        isPreferencesUpdateSuccessful = await updateCustomerProfilePreferences(
          payload
        );

        if (isPreferencesUpdateSuccessful && isContactDeleted) {
          await deleteCustomerProfileEmailContact(secondaryEmail.id);
        }

        if (isPreferencesUpdateSuccessful && isEmailUpdateSuccessful) {
          setUpdatePreferencesSuccess(true);
          setUpdatePreferencesFailed(false);
        }
      } catch {
        setUpdatePreferencesSuccess(false);
        setUpdatePreferencesFailed(true);
      }
    } else {
      setUpdatePreferencesSuccess(true);
      setUpdatePreferencesFailed(false);
    }
  };

  const updateSuccessMessage = (
    <>
      <IconCircle decorative={true} />
      <CenteredHeaderRocket tabIndex={-1} className="sentry-unmask">
        Receipt preferences updated
      </CenteredHeaderRocket>
      <CenteredParagraphRocket className="sentry-unmask">
        Your receipt preferences have been updated.
      </CenteredParagraphRocket>
      <StyledButtonWrapperRocket>
        <InfoPageThemedButtonRocket
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButtonRocket>
      </StyledButtonWrapperRocket>
    </>
  );

  const updateErrorMessage = (
    <>
      <IconCircle decorative={true} hasError={true} />
      <CenteredHeaderRocket
        hasError={true}
        tabIndex={-1}
        className="sentry-unmask"
      >
        Receipt preferences not changed
      </CenteredHeaderRocket>
      <CenteredParagraphRocket className="sentry-unmask">
        An error has occurred. Your receipt preferences have not been updated.
      </CenteredParagraphRocket>
      <StyledButtonWrapperRocket>
        <InfoPageThemedButtonRocket
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButtonRocket>
      </StyledButtonWrapperRocket>
    </>
  );

  return (
    <Layout>
      <ContainerRocket>
        <PageLoader isShown={showLoader} />
        {!showLoader && (
          <>
            {updatePreferencesSuccess &&
              !updatePreferencesFailed &&
              updateSuccessMessage}
            {!updatePreferencesSuccess &&
              updatePreferencesFailed &&
              updateErrorMessage}
            {!isUpdatePreferencesAny && (
              <>
                <HeaderSectionPreferencesRocket data-testid="receipt-preference-header">
                  <RowAlignedDiv>
                    <IconWrapper
                      icon={<IconReceiptOutline height="30" width="30" />}
                      indicator={""}
                    />
                    <HeadingContainerPreferencesRocket>
                      <Heading1Rocket data-testid="preferences-heading">
                        Receipt preferences
                      </Heading1Rocket>
                    </HeadingContainerPreferencesRocket>
                  </RowAlignedDiv>
                </HeaderSectionPreferencesRocket>

                <ReceiptPreferencesContainerRocket>
                  <form
                    data-testid="manage-my-receipt-preferences-form"
                    onSubmit={handleSubmit(submitChangedPreferences)}
                    noValidate
                  >
                    <>
                      <LineLabelRocketStyled className="sentry-unmask">
                        Go paperless with Coles barcode
                      </LineLabelRocketStyled>
                      <StyledOL className="sentry-unmask">
                        <BodyTextRocket>
                          <li>Turn on Digital receipts only</li>
                          <li>
                            Scan your Coles barcode each time you checkout
                            instore
                          </li>
                          <li>
                            To access digital receipts, visit{" "}
                            <LinkRocketStyled
                              tabIndex={0}
                              id="order-and-purchase"
                              data-testid="order-and-purchase"
                              label="Orders & purchases"
                              variant="inline"
                              size="small"
                              href={configuration.cuspActiveOrdersUrl}
                              target="_blank"
                            />
                          </li>
                        </BodyTextRocket>
                      </StyledOL>

                      {!isLoadingCustomerProfile && isBusinessCustomer && (
                        <LineLabelRocketCardStyled className="sentry-unmask">
                          Instore
                        </LineLabelRocketCardStyled>
                      )}

                      {!isLoadingCustomerProfile &&
                        !isLoadingLoyaltyAccount &&
                        !flybuys.hasError &&
                        !isFlybuysLinked &&
                        cardFlyBuysNotLinked}

                      {!showLoader &&
                        (flybuys.hasError || isFlybuysLinked) &&
                        cardFlyBuysLinkedInStorePreference}

                      {!showLoader && cardOnlinePreference}

                      {!showLoader &&
                        (isBusinessCustomer ||
                          flybuys.hasError ||
                          isFlybuysLinked) && (
                          <ReceiptPreferencesButtonsRocket>
                            <ThemedButtonRocket
                              data-testid="save-button-rocket"
                              className="sentry-unmask"
                            >
                              Save
                            </ThemedButtonRocket>
                            <CancelLink
                              tabIndex={0}
                              href={getReturnToLink()}
                              className="sentry-unmask"
                            >
                              Cancel
                            </CancelLink>
                          </ReceiptPreferencesButtonsRocket>
                        )}
                    </>
                  </form>
                </ReceiptPreferencesContainerRocket>
              </>
            )}
          </>
        )}
      </ContainerRocket>
    </Layout>
  );
};

export default ReceiptPreferencesPageRocket;
