import styled from "styled-components";
import { CustomerProfileAddressContact } from "../../store/customer-profile-address/CustomerProfileAddressSlice";
import { rdsPadding } from "@coles/rocket";

const AddressSettingLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  ${rdsPadding.s5.top};
  ${rdsPadding.s6.left};

  flex-direction: column;
`;

const ContactSetting = styled.div`
  display: flex;
`;

interface CustomerProfileAddressContactsRocket {
  recipientFirstName: string;
  recipientLastName: string;
  contacts: Array<CustomerProfileAddressContact>;
}

const format = (contact: string, length: number) => {
  return contact ? contact.replace(/ /g, "").substring(0, length) : "";
};

const AddressContactsRocket = ({
  recipientFirstName,
  recipientLastName,
  contacts,
}: CustomerProfileAddressContactsRocket) => {
  const hasContacts = contacts && contacts.length > 0;

  const email =
    contacts.find((contact) => contact.key === "email")?.value ?? "";
  const phone =
    contacts.find((contact) => contact.key === "phone")?.value ?? "";

  return hasContacts ? (
    <AddressSettingLine>
      <ContactSetting>
        <strong>
          {recipientFirstName} {recipientLastName}
        </strong>
      </ContactSetting>

      {email && (
        <ContactSetting data-testid="email">{format(email, 50)}</ContactSetting>
      )}
      {phone && (
        <ContactSetting data-testid="phone">{format(phone, 10)}</ContactSetting>
      )}
    </AddressSettingLine>
  ) : (
    <></>
  );
};

export default AddressContactsRocket;
