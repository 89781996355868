import {
  InputFieldRocket,
  InputFieldRocketProps,
} from "ccp-common-ui-components";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

type OneTimeCodeRocketInputProps = InputFieldRocketProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type EditableOneTimeCodeRocketProps = OneTimeCodeRocketInputProps & {
  errorMessage?: string;
} & UseFormRegisterReturn;

const OtcInputFieldRocket = styled(InputFieldRocket)`
  width: 100%;
`;

export const EditableOneTimeCodeRocket = React.forwardRef(
  (
    props: OneTimeCodeRocketInputProps & EditableOneTimeCodeRocketProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!props.errorMessage;
    const { size, id, ...rest } = props;

    return (
      <OtcInputFieldRocket
        autoFocus
        id="otc-input-rocket"
        data-testid="editable-one-time-code-input-rocket"
        hasError={hasError}
        labelText="One-time code"
        errorText={props.errorMessage}
        aria-invalid={hasError}
        aria-describedby="editable-one-time-code-input-error-rocket"
        required
        {...rest}
        ref={ref}
      />
    );
  }
);
