import styled from "styled-components";
import {
  unlinkTeamMemberNumberAction,
  setIsUnlinkModelOpen,
} from "../../store/team-member-number/TeamMemberNumberSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { ActionModal } from "../../common/modals/ActionModal";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { DialogModalRocket } from "ccp-common-ui-components";

export const BackgroundDiv = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

export function TeamMemberUnlinkModalContainer() {
  const isRocketEnabled = useRocketEnabled();
  const dispatch = useDispatch();

  const showUnlinkModel = useSelector(
    (state: RootState) => state.teamMemberNumber.isUnlinkModelOpen
  );

  const { teamMember } = useSelector(
    (state: RootState) => state.loyaltyAccounts
  );

  const closeModal = () => {
    dispatch(setIsUnlinkModelOpen(false));
  };

  const unlinkCardRequest = () => {
    dispatch(
      unlinkTeamMemberNumberAction({
        loyaltyAccountId: teamMember.loyaltyAccountId,
      })
    );
  };

  const unlinkHeader = `Unlink mycoles card?`;
  const unlinkBlurb = `Are you sure you want to unlink your mycoles card?`;
  const unlinkButton = `Unlink card`;
  const cancelButton = `Cancel`;

  return (
    <>
      {showUnlinkModel && <BackgroundDiv />}
      {isRocketEnabled ? (
        <DialogModalRocket
          isOpen={showUnlinkModel}
          closeHandler={closeModal}
          titleText={unlinkHeader}
          description={unlinkBlurb}
          confirmButtonText={unlinkButton}
          cancelButtonText={cancelButton}
          confirmHandler={() => unlinkCardRequest()}
          cancelHandler={closeModal}
        />
      ) : (
        <ActionModal
          onSuccess={() => unlinkCardRequest()}
          onRequestClose={() => closeModal()}
          isOpen={showUnlinkModel}
          shouldCloseOnEsc={true}
          header={unlinkHeader}
          blurb={unlinkBlurb}
          yesbutton={unlinkButton}
          nobutton={cancelButton}
        />
      )}
    </>
  );
}
