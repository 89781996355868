import React, { useEffect, useRef, useState } from "react";
import ProfileSetting, { SettingFieldStatus } from "./ProfileSetting";
import { AsyncOperationStatus } from "../../store/async-operation/AsyncOperationSlice";
import { UpdatePendingSpinner } from "./UpdatePendingSpinner";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import ProfileSettingRocket from "./ProfileSettingRocket";

export interface InlineEditProfileSettingProps {
  name: string;
  value: string;
  id: string;
  startEditLabel: string;
  saveLabel: string;
  onEdit: () => void;
  onSave: (value: string) => void;
  saveStatus: AsyncOperationStatus;
  isValid: (value: string) => boolean;
  sanitize: (value: string) => string;
  InvalidMessage: React.FunctionComponent;
  SuccessMessage: React.FunctionComponent;
  FailureMessage: React.FunctionComponent;
}

function InlineEditProfileSetting(props: InlineEditProfileSettingProps) {
  const [inEditMode, setInEditMode] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState(props.value);
  const [validationEnabled, setValidationEnabled] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const isRocketEnabled = useRocketEnabled();
  const messageDictionary = {
    [AsyncOperationStatus.NotStarted]: undefined,
    [AsyncOperationStatus.Pending]: undefined,
    [AsyncOperationStatus.Success]: props.SuccessMessage,
    [AsyncOperationStatus.Failure]: props.FailureMessage,
  };
  const sanitizedValue = props.sanitize(value);
  const isValidValue = props.isValid(sanitizedValue);
  const message = inEditMode
    ? validationEnabled && !isValidValue
      ? props.InvalidMessage
      : undefined
    : messageDictionary[props.saveStatus];

  const enterEditState = () => {
    setInEditMode(true);
    setIsEditClicked(true);
    props.onEdit();
  };

  const exitEditState = () => {
    setInEditMode(false);
    setValue(props.value);
    setIsEditClicked(false);
  };

  const saveValue = () => {
    setInEditMode(false);
    setValue(sanitizedValue);
    if (sanitizedValue !== props.value) {
      props.onSave(sanitizedValue);
    }
  };

  const getInputElement = (referencedElement: HTMLElement | null) => {
    if (!referencedElement || referencedElement.nodeName === "input") {
      return referencedElement;
    } else {
      const inputs = referencedElement.getElementsByTagName("input");
      if (inputs.length > 0) {
        return inputs[0];
      }
      return referencedElement;
    }
  };

  const enableValidationWith = (saveValue: () => void) => {
    return () => {
      if (!validationEnabled) {
        setValidationEnabled(true);
      }
      if (!isValidValue) {
        getInputElement(inputRef.current)?.focus();
      }
      if (isValidValue) {
        saveValue();
        setValidationEnabled(false);
      }
    };
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (props.saveStatus !== AsyncOperationStatus.Pending) {
      setValue(props.value);
    }
  }, [props.saveStatus, props.value]);

  // Focus edit button after going back to read mode
  useEffect(() => {
    const isUpdating = props.saveStatus === AsyncOperationStatus.Pending;
    if (isEditClicked && !inEditMode && editButtonRef.current && !isUpdating) {
      editButtonRef.current.focus();
    }
  }, [inEditMode, isEditClicked, props.saveStatus]);

  return isRocketEnabled ? (
    <ProfileSettingRocket
      name={props.name}
      value={value}
      id={props.id}
      link="Edit"
      handleEdit={enterEditState}
      handleSave={enableValidationWith(saveValue)}
      handleCancel={exitEditState}
      handleChange={updateValue}
      status={
        inEditMode ? SettingFieldStatus.Editable : SettingFieldStatus.Readable
      }
      linkEnabled={isValidValue || !validationEnabled}
      MessageComponent={message}
      editButtonRef={editButtonRef}
      inputRef={inputRef}
      isLoading={props.saveStatus === AsyncOperationStatus.Pending}
    />
  ) : (
    <ProfileSetting
      name={props.name}
      value={value}
      id={props.id}
      link={inEditMode ? props.saveLabel : props.startEditLabel}
      handleClick={
        inEditMode ? enableValidationWith(saveValue) : enterEditState
      }
      handleChange={updateValue}
      status={
        inEditMode ? SettingFieldStatus.Editable : SettingFieldStatus.Readable
      }
      linkEnabled={isValidValue || !validationEnabled}
      MessageComponent={message}
      SpinnerComponent={
        props.saveStatus === AsyncOperationStatus.Pending
          ? UpdatePendingSpinner
          : undefined
      }
      editButtonRef={editButtonRef}
      inputRef={inputRef}
    />
  );
}

export default InlineEditProfileSetting;
