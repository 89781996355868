import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import Setting from "./ProfileSetting";
import { Link } from "react-router-dom";
import { MfaSetting } from "./MfaSetting/MfaSetting";
import { useFeatureFlags } from "../../common/feature-flags/UseFeatureFlags";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import ProfileSettingRocket from "./ProfileSettingRocket";
import { Settings as SettingsRocket } from "./ProfileSettings";
import { rdsMargin, Spacing, theme } from "@coles/rocket";
import { useEffect, useState } from "react";
import { ThemedMessageRocket } from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { UpdateSuccessMessage } from "./UpdateSuccessMessage";
import { resetPasswordUpdateState } from "../../store/customer-profile/CustomerProfileSlice";

export const passwordFillerLength: number = 8;
export const Settings = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
  }
`;

const PasswordFiller = styled.div`
  height: 8px;
  width: 8px;
  background-color: #414240;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 3px;
`;

const PasswordFillerRocket = styled.div`
  height: ${Spacing.s2}px;
  width: ${Spacing.s2}px;
  background-color: ${theme.colorForeground.primary};
  border-radius: 50%;
  display: inline-block;
  ${rdsMargin.s1.right}
`;

const UpdateSuccessMessageNonRocket = styled(UpdateSuccessMessage)`
  padding-top: 16px;
`;

const UpdateSuccessfulMessageRocket = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetPasswordUpdateState());
  }, [dispatch]);
  return (
    <ThemedMessageRocket
      type="success"
      data-testid="password-success"
      text="Your password has been updated."
    />
  );
};

const UpdateSuccessfulMessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetPasswordUpdateState());
  }, [dispatch]);
  return (
    <UpdateSuccessMessageNonRocket
      data-testid="password-success"
      text="Your password has been updated."
    />
  );
};

function SecuritySettings() {
  const { isBcpFeatureEnabled } = useFeatureFlags();
  const isRocketEnabled = useRocketEnabled();
  const [Message, setMessage] = useState<React.FunctionComponent>();
  const updateSuccess = useSelector(
    (state: RootState) => state.customerProfile.isPasswordUpdated
  );

  useEffect(() => {
    if (updateSuccess) {
      setMessage(() =>
        isRocketEnabled
          ? UpdateSuccessfulMessageRocket
          : UpdateSuccessfulMessage
      );
    }
  }, [isRocketEnabled, updateSuccess]);

  return isRocketEnabled ? (
    <SettingsRocket>
      <ProfileSettingRocket
        name="Password"
        value={[...Array(passwordFillerLength)].map((_, position) => {
          return (
            <PasswordFillerRocket
              data-testid="password-filler"
              key={position}
            />
          );
        })}
        link="Change"
        linkEnabled
        id="password"
        editLink="/change-password"
        MessageComponent={Message}
      />
      {!isBcpFeatureEnabled && <MfaSetting />}
    </SettingsRocket>
  ) : (
    <Settings>
      <Setting
        name="Password"
        value={[...Array(passwordFillerLength)].map((_, position) => {
          return (
            <PasswordFiller data-testid="password-filler" key={position} />
          );
        })}
        link={<Link to="/change-password">Change password</Link>}
        linkEnabled={true}
        MessageComponent={Message}
      />
      {!isBcpFeatureEnabled && <MfaSetting />}
    </Settings>
  );
}

export default SecuritySettings;
