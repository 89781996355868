import styled from "styled-components";
import { RocketSettings, Settings } from "../profile/ProfileSettings";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";
import { ThemedButton } from "./ThemedButton";
import { rdsMargin, rdsPadding } from "@coles/rocket";
import {
  BodyTextRocket,
  Heading1Rocket,
  ThemedButtonRocket,
} from "ccp-common-ui-components";

interface CenteredHeaderProps {
  hasError?: boolean;
}

export const CenteredHeader = styled.h1<CenteredHeaderProps>`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  padding-top: 10px;
  text-align: center;
  color: ${(props) => (props.hasError ? headers.error : headers.success)};
  font-family: "Avant Garde";
`;

export const CenteredHeaderRocket = styled(Heading1Rocket)<CenteredHeaderProps>`
  ${rdsPadding.s6.top};
  text-align: center;
  color: ${(props) => (props.hasError ? headers.error : headers.success)};
`;

export const CenteredParagraph = styled.p`
  color: ${headers.black900};
  opacity: 0.85;
  font-family: "Source Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;

export const CenteredParagraphRocket = styled(BodyTextRocket).attrs({
  variant: "body_300",
})`
  text-align: center;
  ${rdsPadding.s6.top};
  ${rdsMargin.s7.bottom};
`;

export const StyledButtonWrapperRocket = styled.div`
  display: flex;
  flex-direction: column;
  ${rdsPadding.s3.all};
  align-items: center;
`;

export const InfoContainerRocket = styled(RocketSettings)`
  ${rdsMargin.s8.bottom};
  ${rdsPadding.s8.vertical};
  ${rdsPadding.s6.horizontal};
  align-self: center;
  width: 350px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const InfoContainer = styled(Settings)`
  margin-bottom: 50px;
  padding: 50px 25px;
  width: 514px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const InfoPageThemedButton = styled(ThemedButton)`
  min-width: 50%;
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

export const InfoPageThemedButtonRocket = styled(ThemedButtonRocket)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;
