import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "../../styled/Layout";
import { RootState } from "../../../store/Store";
import { StyledButtonWrapper } from "../../styled/StyledButtonWrapper";

import { SubPageHeader, SubPageHeaderRocket } from "../../styled/SubPageHeader";
import { PageLoader } from "../../common/PageLoader";
import { IconCircle } from "../../common/IconCircle";
import {
  CenteredHeader,
  CenteredHeaderRocket,
  CenteredParagraph,
  CenteredParagraphRocket,
  InfoPageThemedButton,
  InfoPageThemedButtonRocket,
  StyledButtonWrapperRocket,
} from "../../styled/InfoPage";
import {
  CancelLink,
  MarketingPreferencesContainer,
  MarketingPreferencesDescription,
  MarketingPreferencesButtons,
  ThemedSavePerferencesButton,
  MarketingPreferencesContainerRocket,
  MarketingPreferencesDescriptionRocket,
  ThemedSavePerferencesButtonRocket,
  MarketingPreferencesButtonsRocket,
  CancelLinkRocket,
  StyledPrivacyParagraph,
  StyledParagraph,
} from "./MarketingPreferencesPageStyles";
import {
  ColesPrivacyUrl,
  MarketingPreferencesCheckbox,
} from "./MarketingPreferencesCheckbox";
import { getCustomerProfilePreferencesAction } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import { MarketingPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import { redirect } from "../../../common/utils/NavigationHelpers";
import { updateCustomerProfilePreferences } from "../../../api/preferences/CustomerProfilePreferencesApi";
import { mapPreferences } from "../Utils/PreferencesUtils";
import configuration from "../../../config/Configuration";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import { LinkRocket, CheckBoxRocket } from "ccp-common-ui-components";

const getBrandSpecificDescriptor = (brand?: string) => {
  switch (brand) {
    case "cexp":
      return "Coles Express";
    case "supermarket":
      return "Coles Supermarkets";
    default:
      return "Coles Supermarkets";
  }
};

const generateModeLabel = (mode: string) => {
  switch (mode) {
    case "sms":
      return "SMS";
    case "push":
      return "Push notifications";
    case "paid":
      return "Targeted digital advertising";
    default:
      return `${mode[0].toUpperCase()}${mode.slice(1)}`;
  }
};

const privacyLink = (
  <LinkRocket
    tabIndex={0}
    href={ColesPrivacyUrl}
    className="sentry-unmask"
    id="privacy-marketing-pref"
    data-testid="test-privacy-marketing-pref"
    label="privacy page"
    size="extraSmall"
    target="_blank"
  />
);

const generateModeDescription = (mode: string) => {
  switch (mode) {
    case "paid":
      return "Receive relevant ads on partner platforms (such as social media, streaming, websites and apps) based on your personal information. Find out more on our ";
  }
};

const showPrivacyLink = (mode: string) => {
  switch (mode) {
    case "paid":
      return true;
    default:
      return false;
  }
};

const MarketingPreferencesPage = () => {
  const history = useHistory();
  const ismarketingPreferencePushFeatureFlagOn =
    configuration.marketingPreferencePushFeature;

  const ismarketingPreferencePaidFeatureFlagOn =
    configuration.marketingPreferencePaidFeature;

  const [editablePreferences, setEditablePreferences] = useState<
    MarketingPreference[]
  >([]);
  const [isMarketingPreferencesChanged, setMarketingPreferencesStatus] =
    useState<boolean>(false);
  const [updatePreferencesSuccess, setUpdatePreferencesSuccess] =
    useState<boolean>(false);
  const [updatePreferencesFailed, setUpdatePreferencesFailed] =
    useState<boolean>(false);
  const [currentlySelectedCount, setCurrentlySelectedCount] =
    useState<number>(0);
  const { brand } = useParams<{ brand?: string }>();
  const dispatch = useDispatch();
  const { marketingPreferences, isLoadingPreferences } = useSelector(
    (state: RootState) => state.customerProfilePreferences
  );

  const isUpdatePreferencesSuccess =
    updatePreferencesSuccess && !updatePreferencesFailed;
  const isUpdatePreferencesError =
    !updatePreferencesSuccess && updatePreferencesFailed;
  const isUpdatePreferencesAny =
    updatePreferencesSuccess || updatePreferencesFailed;

  const showLoader = isLoadingPreferences;
  const isRocketEnabled = useRocketEnabled();

  useEffect(() => {
    setEditablePreferences(
      marketingPreferences.filter((pref) => pref.brand === brand)
    );
  }, [marketingPreferences, brand]);

  useEffect(() => {
    dispatch(getCustomerProfilePreferencesAction());
  }, [dispatch]);

  useEffect(() => {
    const numSelected = editablePreferences.filter(
      (p) => p.value === true
    ).length;
    setCurrentlySelectedCount(numSelected);
  }, [editablePreferences]);

  const changeHandler = (pref: MarketingPreference) => {
    const clonedEditablePreferences: MarketingPreference[] = JSON.parse(
      JSON.stringify(editablePreferences)
    );
    const targetItemIndex = clonedEditablePreferences.findIndex(
      (i) => i.name === pref.name
    );
    if (targetItemIndex !== -1) {
      clonedEditablePreferences[targetItemIndex].value = !pref.value;
      setEditablePreferences(clonedEditablePreferences);
      setMarketingPreferencesStatus(true);
    }
  };

  const selectUnselectAllHandler = () => {
    const clonedEditablePreferences: MarketingPreference[] = JSON.parse(
      JSON.stringify(editablePreferences)
    );
    clonedEditablePreferences.map((p) => (p.value = false));
    setEditablePreferences(clonedEditablePreferences);
    setMarketingPreferencesStatus(true);
  };

  const handleSuccessOrErrorClick = () => {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  };

  const submitChangedPreferences = async () => {
    const payload = mapPreferences([
      ...(isMarketingPreferencesChanged ? editablePreferences : []),
    ]);

    const res = await updateCustomerProfilePreferences(payload);
    if (res) {
      setUpdatePreferencesSuccess(true);
      setUpdatePreferencesFailed(false);
    } else {
      setUpdatePreferencesSuccess(false);
      setUpdatePreferencesFailed(true);
    }
  };

  const updateSuccessMessage = (
    <>
      <IconCircle decorative={true} />
      <CenteredHeader tabIndex={-1} className="sentry-unmask">
        Marketing preferences updated
      </CenteredHeader>
      <CenteredParagraph className="sentry-unmask">
        Your marketing preferences have been updated successfully.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const updateSuccessMessageRocket = (
    <>
      <IconCircle decorative={true} />
      <CenteredHeaderRocket tabIndex={-1} className="sentry-unmask">
        Marketing preferences updated
      </CenteredHeaderRocket>
      <CenteredParagraphRocket className="sentry-unmask">
        Your marketing preferences have been updated successfully.
      </CenteredParagraphRocket>
      <StyledButtonWrapperRocket>
        <InfoPageThemedButtonRocket
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButtonRocket>
      </StyledButtonWrapperRocket>
    </>
  );

  function isMarketingPreferencePushFeatureFlagOn(pref: any): boolean {
    return (
      (!ismarketingPreferencePushFeatureFlagOn && pref.mode != "push") ||
      ismarketingPreferencePushFeatureFlagOn
    );
  }

  function isMarketingPreferencePaidFeatureFlagOn(pref: any): boolean {
    return (
      (!ismarketingPreferencePaidFeatureFlagOn && pref.mode != "paid") ||
      ismarketingPreferencePaidFeatureFlagOn
    );
  }

  const updateErrorMessage = (
    <>
      <IconCircle decorative={true} hasError={true} />
      <CenteredHeader hasError={true} tabIndex={-1} className="sentry-unmask">
        Marketing preferences not changed
      </CenteredHeader>
      <CenteredParagraph className="sentry-unmask">
        An error has occurred. Your marketing preferences have not been updated.
      </CenteredParagraph>
      <StyledButtonWrapper>
        <InfoPageThemedButton
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButton>
      </StyledButtonWrapper>
    </>
  );

  const updateErrorMessageRocket = (
    <>
      <IconCircle decorative={true} hasError={true} />
      <CenteredHeaderRocket
        hasError={true}
        tabIndex={-1}
        className="sentry-unmask"
      >
        Marketing preferences not changed
      </CenteredHeaderRocket>
      <CenteredParagraphRocket className="sentry-unmask">
        An error has occurred. Your marketing preferences have not been updated.
      </CenteredParagraphRocket>
      <StyledButtonWrapperRocket>
        <InfoPageThemedButtonRocket
          onClick={() => {
            handleSuccessOrErrorClick();
          }}
          className="sentry-unmask"
        >
          Continue
        </InfoPageThemedButtonRocket>
      </StyledButtonWrapperRocket>
    </>
  );
  const rocketLayout = (
    <Layout>
      <SubPageHeaderRocket className="sentry-unmask">{`Manage my ${getBrandSpecificDescriptor(
        brand
      )} marketing preferences`}</SubPageHeaderRocket>
      <PageLoader isShown={showLoader} />
      {!showLoader && (
        <MarketingPreferencesContainerRocket>
          {isUpdatePreferencesSuccess && updateSuccessMessageRocket}
          {isUpdatePreferencesError && updateErrorMessageRocket}
          {!isUpdatePreferencesAny && (
            <>
              <MarketingPreferencesDescriptionRocket className="sentry-unmask">
                How would you like to receive weekly specials, offers and
                personalised recommendations?
              </MarketingPreferencesDescriptionRocket>
              {editablePreferences &&
                editablePreferences.length &&
                editablePreferences.length > 0 &&
                editablePreferences.map((pref) =>
                  isMarketingPreferencePushFeatureFlagOn(pref) &&
                  isMarketingPreferencePaidFeatureFlagOn(pref) ? (
                    <>
                      <CheckBoxRocket
                        key={pref.name}
                        id={pref.name}
                        name={pref.name}
                        data-testid={`test-${pref.name}`}
                        label={generateModeLabel(pref.mode)}
                        error={false}
                        isChecked={pref.value}
                        onChange={() => {
                          changeHandler(pref);
                        }}
                      />
                      {pref.mode == "paid" ? (
                        <StyledPrivacyParagraph>
                          {generateModeDescription(pref.mode)} {privacyLink}.
                        </StyledPrivacyParagraph>
                      ) : null}
                    </>
                  ) : null
                )}
              <MarketingPreferencesButtonsRocket>
                <CheckBoxRocket
                  id="select-unselect-all"
                  name="select-unselect-all"
                  data-testid="test-select-unselect-all"
                  label={`Unsubscribe from all marketing messages & communications`}
                  error={false}
                  isChecked={currentlySelectedCount === 0}
                  onChange={() => {
                    selectUnselectAllHandler();
                  }}
                />
                <StyledParagraph>
                  You'll still get important communications about your account
                  and orders.
                </StyledParagraph>
                <ThemedSavePerferencesButtonRocket
                  variant="primary"
                  aria-label="Save preference for marketing communications"
                  data-testid="save-preferences"
                  onClick={() => {
                    submitChangedPreferences();
                  }}
                  className="sentry-unmask"
                  isFullWidth
                  size="large"
                  label="Save"
                ></ThemedSavePerferencesButtonRocket>
                <CancelLinkRocket
                  tabIndex={0}
                  href={getReturnToLink()}
                  className="sentry-unmask"
                  id="cancel-marketing-pref"
                  data-testid="cancel-marketing-pref"
                  label="Cancel"
                  size="large"
                ></CancelLinkRocket>
              </MarketingPreferencesButtonsRocket>
            </>
          )}
        </MarketingPreferencesContainerRocket>
      )}
    </Layout>
  );

  const nonRocketLayout = (
    <Layout>
      <SubPageHeader className="sentry-unmask">{`Manage my ${getBrandSpecificDescriptor(
        brand
      )} marketing preferences`}</SubPageHeader>
      <PageLoader isShown={showLoader} />
      {!showLoader && (
        <MarketingPreferencesContainer>
          {isUpdatePreferencesSuccess && updateSuccessMessageRocket}
          {isUpdatePreferencesError && updateErrorMessageRocket}
          {!isUpdatePreferencesAny && (
            <>
              <MarketingPreferencesDescription className="sentry-unmask">
                How would you like to receive weekly specials, offers and
                personalised recommendations?
              </MarketingPreferencesDescription>
              {editablePreferences &&
                editablePreferences.length &&
                editablePreferences.length > 0 &&
                editablePreferences.map((pref) =>
                  isMarketingPreferencePushFeatureFlagOn(pref) &&
                  isMarketingPreferencePaidFeatureFlagOn(pref) ? (
                    <MarketingPreferencesCheckbox
                      key={pref.name}
                      id={pref.name}
                      name={pref.name}
                      testid={`test-${pref.name}`}
                      label={generateModeLabel(pref.mode)}
                      hasError={false}
                      checked={pref.value}
                      description={generateModeDescription(pref.mode)}
                      showPrivacyLink={showPrivacyLink(pref.mode)}
                      onChange={() => {
                        changeHandler(pref);
                      }}
                    />
                  ) : null
                )}
              <MarketingPreferencesButtons>
                <MarketingPreferencesCheckbox
                  id="select-unselect-all"
                  name="select-unselect-all"
                  testid="test-select-unselect-all"
                  label={`Unsubscribe from all marketing messages & communications`}
                  hasError={false}
                  checked={currentlySelectedCount === 0}
                  description="You'll still get important communications about your account and orders."
                  onChange={() => {
                    selectUnselectAllHandler();
                  }}
                />
                <ThemedSavePerferencesButton
                  aria-label="Save preference for marketing communications"
                  data-testid="save-preferences"
                  onClick={() => {
                    submitChangedPreferences();
                  }}
                  className="sentry-unmask"
                >
                  Save
                </ThemedSavePerferencesButton>
                <CancelLink
                  tabIndex={0}
                  href={getReturnToLink()}
                  className="sentry-unmask"
                >
                  Cancel
                </CancelLink>
              </MarketingPreferencesButtons>
            </>
          )}
        </MarketingPreferencesContainer>
      )}
    </Layout>
  );

  return isRocketEnabled ? rocketLayout : nonRocketLayout;
};

export default MarketingPreferencesPage;
