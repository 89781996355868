import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";
import { Heading1Rocket } from "ccp-common-ui-components";
import { rdsMargin } from "@coles/rocket";

export const SubPageHeader = styled.h1`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-size: 27px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  margin: 0 0 24px 0;

  @media ${screenSize.mobile} {
    font-size: 27px;
    line-height: 32px;
  }
`;

export const SubPageHeaderRocket = styled(Heading1Rocket)`
  text-align: left;
  ${rdsMargin.s3.bottom};
`;
