import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowTermsAndConditions,
  setShowDeleteAccount,
  setShowCollectionStatement,
} from "../store/user-acceptances/UserAcceptancesSlice";
import { RootState } from "../store/Store";
import useRocketEnabled from "../hooks/useRocketEnabled";
import { LinkRocket } from "ccp-common-ui-components";
import { rdsMargin } from "@coles/rocket";
import { screenSize } from "./styles/ScreenSizes";

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  font-family: "Source Sans";
  font-size: 16px;
  line-height: 22px;
  margin: 4px 0;
  margin-top: 22px;
  margin-bottom: 12px;
`;

const MainColumnFooter = styled.footer`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 2vh;
  background-color: var(--colors-colorBackground-secondary-default);
  box-sizing: border-box;
  width: 100%;
  @media ${screenSize.mobile} {
    align-self: stretch;
    max-width: 100%;
  }
`;

const ContainerRocket = styled.div`
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  line-height: 22px;
  gap: 32px;
  ${rdsMargin.s5.left};
  ${rdsMargin.s5.right};
  ${rdsMargin.s5.top};
  ${rdsMargin.s5.bottom};
  @media ${screenSize.mobile} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    max-width: 100%;

    & > * {
      flex: 0 0 calc(50% - 8px);
      text-align: left;
    }
  }
`;

const Link = styled.a`
  color: #414240;
  text-decoration: underline;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    padding-top: 12px;
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 9px;
  border-top: 1px solid #414240;
  margin: 0 15px;
  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    margin-top: 18px;
  }
`;

function AppFooter() {
  const dispatch = useDispatch();
  const pendingDeletion = useSelector(
    (state: RootState) => state.customerProfile.profileFields?.pendingDeletion
  );

  const isRocketEnabled = useRocketEnabled();

  return isRocketEnabled ? (
    <MainColumnFooter>
      <ContainerRocket>
        <LinkRocket
          href="https://www.coles.com.au/important-information/privacy-policy"
          target="_blank"
          label="Privacy policy"
          variant="light"
        />

        <LinkRocket
          href=""
          onClick={(e) => {
            e.preventDefault();
            dispatch(setShowCollectionStatement(true));
          }}
          variant="light"
          label="Collection notice"
        />

        <LinkRocket
          href=""
          onClick={(e) => {
            e.preventDefault();
            dispatch(setShowTermsAndConditions(true));
          }}
          variant="light"
          label="Terms and conditions"
        />

        {!pendingDeletion && (
          <>
            <LinkRocket
              href=""
              onClick={(e) => {
                e.preventDefault();
                dispatch(setShowDeleteAccount(true));
              }}
              variant="light"
              label="Delete my account"
            />
          </>
        )}
      </ContainerRocket>
    </MainColumnFooter>
  ) : (
    <Container>
      <Link
        href="https://www.coles.com.au/important-information/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </Link>
      <Separator />
      <Link
        href=""
        onClick={(e) => {
          e.preventDefault();
          dispatch(setShowCollectionStatement(true));
        }}
      >
        Collection Notice
      </Link>
      <Separator />
      <Link
        href=""
        onClick={(e) => {
          e.preventDefault();
          dispatch(setShowTermsAndConditions(true));
        }}
      >
        Terms &amp; Conditions
      </Link>

      {!pendingDeletion && (
        <>
          <Separator />
          <Link
            href=""
            onClick={(e) => {
              e.preventDefault();
              dispatch(setShowDeleteAccount(true));
            }}
          >
            Delete my account
          </Link>
        </>
      )}
    </Container>
  );
}

export default AppFooter;
