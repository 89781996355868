import React from "react";
import { calculatePasswordStrengthScore } from "./PasswordStrengthCalculator";
import { PasswordStrengthEstimate } from "ccp-common-ui-components";
import { ThemedInputDiv } from "../../components/changePassword/ChangePasswordFormStyles";

const generatePasswordStrengthLevel = (input: number) => {
  if (input >= 80) {
    return 3;
  }
  if (input >= 60) {
    return 2;
  }
  return 1;
};

export interface PasswordStrengthCheckerProps {
  password: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
}

export const PasswordStrengthCheckerRocket = (
  props: PasswordStrengthCheckerProps
) => {
  const oldPasswordStrengthScore = calculatePasswordStrengthScore(
    props.password,
    props.firstName,
    props.lastName,
    props.mobile,
    props.email
  );
  const showPasswordStrength = props.password && props.password.length >= 8;
  const passwordStrengthValue = generatePasswordStrengthLevel(
    oldPasswordStrengthScore
  );

  return (
    <ThemedInputDiv>
      <PasswordStrengthEstimate
        strength={showPasswordStrength ? passwordStrengthValue : 0}
      />
    </ThemedInputDiv>
  );
};
