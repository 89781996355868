import React from "react";
import styled from "styled-components";

import { screenSize } from "./common/styles/ScreenSizes";
import AppHeader from "./common/AppHeader";
import { PageLoader } from "./components/common/PageLoader";
import { rdsMargin, Spacing } from "@coles/rocket";
import AppFooter from "./common/AppFooter";

const MainColumn = styled.main<{ isNarrow?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 90vh;
  ${rdsMargin.s11.horizontal};
  width: 67.5vw;
  max-width: ${({ isNarrow }) => (isNarrow ? "30em" : "55em")};
  @media ${screenSize.tablet} {
    width: 80.5vw;
    min-width: 620px;
  }
  @media ${screenSize.mobile} {
    align-self: stretch;
    ${rdsMargin.s5.horizontal};
    width: calc(100% - (2 * ${Spacing.s5}px));
    max-width: 100%;
  }
`;

const StretchContent = styled.div`
  flex-grow: 1;
`;

interface PageLayoutProps {
  backButtonUrl?: string;
  isLoading: boolean;
  children: React.ReactNode;
  isNarrow?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  backButtonUrl,
  isLoading,
  isNarrow,
  children,
}) => {
  return (
    <>
      {isLoading ? (
        <MainColumn isNarrow={isNarrow}>
          <AppHeader backButtonUrl={backButtonUrl} />
          <PageLoader isShown={isLoading} />
        </MainColumn>
      ) : (
        <MainColumn isNarrow={isNarrow}>
          <AppHeader backButtonUrl={backButtonUrl} />
          <StretchContent>{children}</StretchContent>
        </MainColumn>
      )}
      <AppFooter />
    </>
  );
};

export default PageLayout;
