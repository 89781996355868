import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { ActionModal } from "./ActionModal";
import {
  setDeleteAddressModalVisibility,
  deleteAddress,
} from "../../store/add-address/AddAddressSlice";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { BodyTextRocket, DialogModalRocket } from "ccp-common-ui-components";
import { rdsMargin } from "@coles/rocket";

export const BackgroundDiv = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const DescriptionText = styled(BodyTextRocket).attrs({
  variant: "body_300",
})`
  ${rdsMargin.s3.bottom};
`;

export function AddressActionModalContainer() {
  const isRocketEnabled = useRocketEnabled();

  const dispatch = useDispatch();

  const { showDeleteAddressModal, addressToBeDeleted } = useSelector(
    (state: RootState) => state.addAddress
  );

  const closeModal = () => {
    dispatch(setDeleteAddressModalVisibility(false));
  };

  const deleteAccountRequest = () => {
    if (addressToBeDeleted) {
      dispatch(deleteAddress(addressToBeDeleted));
    }
  };

  const deleteHeader = `Delete address?`;
  const deleteBlurb = `This will remove the address from all Coles channels.`;
  const deleteYesButton = `Delete address`;
  const deleteNoButton = `Cancel`;

  const description = (
    <>
      <DescriptionText>
        Are you sure you want to delete{" "}
        <strong>{addressToBeDeleted?.FullAddress}</strong> from your address
        book?
      </DescriptionText>

      <DescriptionText>
        This will remove the address from all Coles channels.
      </DescriptionText>
    </>
  );

  const deleteAddressModal = isRocketEnabled ? (
    <DialogModalRocket
      isOpen={showDeleteAddressModal}
      confirmHandler={() => deleteAccountRequest()}
      cancelHandler={() => closeModal()}
      titleText={deleteHeader}
      confirmButtonText="Delete"
      cancelButtonText={deleteNoButton}
      description={description}
    ></DialogModalRocket>
  ) : (
    <ActionModal
      onSuccess={() => deleteAccountRequest()}
      onRequestClose={() => closeModal()}
      isOpen={showDeleteAddressModal}
      shouldCloseOnEsc={true}
      header={deleteHeader}
      blurb={deleteBlurb}
      yesbutton={deleteYesButton}
      nobutton={deleteNoButton}
    />
  );

  return (
    <>
      {showDeleteAddressModal && <BackgroundDiv />}
      {deleteAddressModal}
    </>
  );
}
